.header-menu-pane {
  display: flex;
  align-items: center;
  column-gap: 3rem;
  .app-logo {
    height: 1.6rem;
    cursor: pointer;
    @media (min-width: 768px) {
      height: 1.8rem;
    }
  }
}
