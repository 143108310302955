.payment-success-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    width: 80%;
    text-align: center;
    font-family: 'bw_modelicalight';
    font-size: 2rem;
    line-height: 3.5rem;
    margin-bottom: 3rem;
  }
}
