.celebration-container {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 101;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  & > div {
    height: 100%;
    width: 100%;
  }
}
