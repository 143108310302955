@import '~@theme';

.input-slider-container {
  label {
    display: block;
    margin-bottom: 0.7rem;
    font-size: 0.9rem;
  }
  .input-slider-wrapper {
    height: 2rem;
    width: 100%;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    .horizontal-slider {
      width: 100%;
      .slider-thumb {
        height: 40px;
        width: 40px;
        top: -17.5px;
        border-radius: 50%;
        background-color: #fafafa;
        border: 1px solid #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'bw_modelicamedium';
        font-size: 0.85rem;
        cursor: grab;
        outline: none;
        box-shadow: 0.1px 0.1px 0.2px rgba(#000, 0.1), 0.8px 0.3px 1px -0.4px rgba(#000, 0.1),
          1.4px 0.6px 1.7px -0.7px rgba(#000, 0.1), 2.3px 1px 2.8px -1.1px rgba(#000, 0.1),
          3.7px 1.6px 4.5px -1.4px rgba(#000, 0.1), 5.8px 2.6px 7.2px -1.8px rgba(#000, 0.1),
          8.9px 3.9px 10.9px -2.1px rgba(#000, 0.1), 13.1px 5.7px 16.1px -2.5px rgba(#000, 0.1);
        &:hover {
          background-color: #efefef;
        }
      }
      .slider-track {
        height: 5px;
        border-radius: 10px;
        &.slider-track-0 {
          background-color: #000;
        }
        &.slider-track-1 {
          background-color: #e5e6e8;
        }
      }
    }
  }
}
