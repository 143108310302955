@import '~@theme';

footer {
  height: 12rem;
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    height: 10rem;
  }
  .footer-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
    @media (min-width: 1024px) {
      width: $sdWidth;
    }
    @media (min-width: 1280px) {
      width: $hdWidth;
    }
    @media (min-width: 1536px) {
      width: $uhdWidth;
    }
  }
}
