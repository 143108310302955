.task-item-container {
  position: relative;
  background: #f9f9f9;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  padding-top: 1rem;
  .task-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
    padding: 0rem 1.2rem;
    .info-box {
      display: flex;
      align-items: center;
      .avatar-wrapper {
        height: 1.3rem;
        width: 1.8rem;
        .avatar-initials-wrapper {
          border-radius: 0.4rem;
          font-size: 0.5rem;
        }
      }
      .lead {
        position: relative;
        margin-left: 0.5rem;
        margin-right: 1rem;
        &::after {
          position: absolute;
          content: '';
          height: 1.3rem;
          width: 1px;
          margin: auto;
          top: 0;
          bottom: 0;
          right: -0.5rem;
          background-color: #a2a6a8;
        }
      }
      .time-taken {
        &.breached {
          color: #ff0054;
        }
      }
    }
  }
  h4 {
    font-family: 'bw_modelicabold';
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
    letter-spacing: 0.1px;
    padding: 0rem 1.2rem;
  }
  p {
    font-family: 'bw_modelicaregular';
    font-size: 0.85rem;
    line-height: 1.2rem;
    padding: 0rem 1.2rem 1rem;
  }
}
