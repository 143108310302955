.agenda-item-container {
  position: relative;
  background: #f9f9f9;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  padding-top: 1rem;
  opacity: 0.6;
  .agenda-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
    padding: 0rem 1.2rem;
    .info-box {
      display: flex;
      align-items: center;
      .avatar-wrapper {
        height: 1.3rem;
        width: 1.8rem;
        .avatar-initials-wrapper {
          border-radius: 0.4rem;
          font-size: 0.5rem;
        }
      }
      .lead {
        position: relative;
        margin-left: 0.5rem;
        margin-right: 1rem;
        &::after {
          position: absolute;
          content: '';
          height: 1.3rem;
          width: 1px;
          margin: auto;
          top: 0;
          bottom: 0;
          right: -0.5rem;
          background-color: #a2a6a8;
        }
      }
      .time-taken {
        &.breached {
          color: #ff0054;
        }
      }
    }
    .action-box {
      button.primary {
        font-family: 'bw_modelicaextrabold';
        font-size: 0.55rem;
        padding: 3px 7px;
        text-transform: uppercase;
      }
    }
  }
  h4 {
    font-family: 'bw_modelicabold';
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
    letter-spacing: 0.1px;
    padding: 0rem 1.2rem;
  }
  p {
    font-family: 'bw_modelicaregular';
    font-size: 0.85rem;
    line-height: 1.2rem;
    padding: 0rem 1.2rem 1rem;
  }
  &.active {
    border-bottom: none;
    box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.05), 1.4px 1.8px 2.6px -0.8px hsla(0deg, 0%, 0%, 0.05),
      3.4px 4.6px 6.4px -1.7px hsla(0deg, 0%, 0%, 0.05), 8.4px 11.2px 15.8px -2.5px hsla(0deg, 0%, 0%, 0.05);
  }
  &.available {
    opacity: 1;
  }
  &.complete {
    opacity: 0.6;
    &::after {
      position: absolute;
      top: 0;
      right: 1rem;
      font-family: 'icomoon' !important;
      font-size: 0.8rem;
      content: '\e906';
      height: 20px;
      width: 20px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #009b2b;
      border: 1px solid #018025;
      border-top: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
}
