.time-slider-wrapper {
  position: relative;
  left: -1px;
  height: 2rem;
  width: calc(100% + 2px);
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  .time-slider-container {
    margin: 1rem 0;
    position: absolute;
    top: -1rem;
    height: 2px;
    width: 100%;
    background: #d8e0e8;
    .time-info {
      position: absolute;
      bottom: -21px;
      font-family: 'bw_modelicaregular';
      font-size: 0.7rem;
      &.start-time-info {
        left: 1.2rem;
      }
      &.end-time-info {
        right: 1.2rem;
      }
    }
    .time-slider-knob {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      height: 10px;
      width: 2px;
      background: #d8e0e8;
    }
    .time-slider-completed-bar {
      position: absolute;
      left: 0;
      height: 100%;
      background-color: #d8e0e8;
    }
    &.safe {
      &::before {
        background: #38b000;
      }
      .time-slider-knob {
        background: #38b000;
      }
      .time-slider-completed-bar {
        background-color: #38b000;
      }
    }
    &.okayish {
      &::before {
        background: #ffbd00;
      }
      .time-slider-knob {
        background: #ffbd00;
      }
      .time-slider-completed-bar {
        background-color: #ffbd00;
      }
    }
    &.almost {
      &::before {
        background: #fb5607;
      }
      .time-slider-knob {
        background: #fb5607;
      }
      .time-slider-completed-bar {
        background-color: #fb5607;
      }
    }
    &.done {
      &::before {
        background: #ff0054;
      }
      .time-slider-knob {
        background: #ff0054;
      }
      .time-slider-completed-bar {
        background-color: #ff0054;
      }
    }
  }
}
