.pricing-page-container {
  margin-top: 3rem;
  margin-bottom: 10rem;
  .roi-container {
    margin-bottom: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h1 {
      font-family: 'bw_modelicablack';
      font-size: 9rem;
      margin-bottom: 3rem;
    }
    h3 {
      width: 70%;
      font-family: 'bw_modelicaextrabold';
      font-size: 2.6rem;
      line-height: 3.8rem;
      margin-bottom: 2rem;
    }
    p {
      width: 80%;
      font-family: 'bw_modelicalight';
      font-size: 1.4rem;
      line-height: 1.6em;
      margin-bottom: 2rem;
      &.separate {
        width: 96%;
        margin: 2rem 0 5rem;
        padding: 3rem 8% 0;
        border-top: 1px solid #c1c5c8;
        font-size: 0.95rem;
        line-height: 1.4rem;
        @media (min-width: 1024px) {
          font-size: 1.1rem;
          line-height: 1.7rem;
        }
      }
    }
  }
  .plans-container {
    margin-bottom: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h2 {
      font-family: 'bw_modelicaextrabold';
      font-size: 2.3rem;
    }
    .term-container {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      label.term {
        background-color: #d6dce2;
        color: #fff;
        padding: 6px 15px;
        font-size: 1rem;
        font-family: 'bw_modelicaextrabold';
        letter-spacing: -1px;
        border-radius: 5px;
        transform: skew(-10deg);
        &.active {
          background-color: #000;
        }
      }
      .switch-wrapper {
        scale: 60%;
      }
    }
    .plan-illustration {
      display: flex;
      column-gap: 3rem;
      position: relative;
      .feature-list {
        width: 10rem;
        text-align: right;
        margin-top: 5rem;
        div {
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 10rem;
          font-size: 0.95rem;
          line-height: 1.3rem;
        }
      }
      .plan-features {
        width: 8rem;
        cursor: pointer;
        div {
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'bw_modelicamedium';
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
        }
        label {
          font-family: 'bw_modelicabold';
          font-size: 1.1rem;
        }
        .available {
          position: relative;
          height: 1.3rem;
          width: 1.3rem;
          border-radius: 5rem;
          background-color: #000;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          &::after {
            position: absolute;
            height: 100%;
            width: 100%;
            padding-top: 0.05rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'icomoon';
            font-size: 0.7rem;
            color: #fff;
            content: '\e906';
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
          }
        }
        &.active {
          color: #a9f70d;
          .available {
            background-color: #a9f70d;
            &::after {
              color: #000;
            }
          }
        }
      }
      .active-plan-indicator {
        position: absolute;
        width: 8rem;
        height: 102%;
        top: -1%;
        right: 0;
        z-index: -1;
        border-radius: 1rem;
        background: -webkit-linear-gradient(90deg, rgba(14, 21, 30, 0.78), rgb(14, 21, 30));
        background: linear-gradient(90deg, rgba(14, 21, 30, 0.78), rgb(14, 21, 30));
        border: 1px solid rgba(27, 42, 60, 0.7);
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.09), 2.2px 2.9px 4.1px -0.3px hsla(0deg, 0%, 0%, 0.09),
          4px 5.3px 7.5px -0.6px hsla(0deg, 0%, 0%, 0.09), 6.2px 8.4px 11.7px -0.9px hsla(0deg, 0%, 0%, 0.09),
          9.5px 12.7px 17.8px -1.2px hsla(0deg, 0%, 0%, 0.09), 14.1px 19px 26.6px -1.6px hsla(0deg, 0%, 0%, 0.09),
          20.8px 27.9px 39.2px -1.9px hsla(0deg, 0%, 0%, 0.09), 29.8px 40px 56.1px -2.2px hsla(0deg, 0%, 0%, 0.09),
          41.8px 56.1px 78.7px -2.5px hsla(0deg, 0%, 0%, 0.09);
        overflow: hidden;
      }
    }
  }
  .estimation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h2 {
      font-family: 'bw_modelicaextrabold';
      font-size: 2.3rem;
    }
    .count-box {
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      label {
        font-size: 1rem;
      }
      input {
        font-family: 'bw_modelicaextrabold';
        font-size: 10rem;
        text-align: center;
        width: 40%;
        border: none;
        background: transparent;
      }
    }
    .estimate-result-wrapper {
      margin: 4rem 0 8rem;
      display: flex;
      align-items: center;
      text-align: left;
      column-gap: 6rem;
      .value-box {
        padding: 1rem 3rem;
        border-radius: 2rem;
        background-color: #f1f2f4;
        h3 {
          position: relative;
          font-family: 'bw_modelicaextrabold';
          font-size: 6rem;
          &::before {
            position: absolute;
            content: '$';
            font-family: 'bw_modelicamedium';
            font-size: 1.3rem;
            left: -1.2rem;
            top: 0.8rem;
          }
        }
        p {
          font-family: 'bw_modelicalight';
          font-size: 0.85rem;
          line-height: 1.1rem;
          letter-spacing: 0.1px;
        }
      }
    }
  }
}
