.user-search-field-container {
  position: relative;
  .search-field-wrapper {
    border-radius: 0.5rem;
    background-color: #fff;
    border: 1px solid #cacccd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input-field-container {
      width: 85%;
      .input-field-wrapper {
        border: none;
      }
    }
    .dot-loader-wrapper {
      width: 15%;
      .dot-loader {
        position: relative;
        margin: auto;
        right: 0;
        width: 7px;
        height: 7px;
        border-radius: 5px;
        background-color: #b9c5cf;
        color: #b9c5cf;
        animation: dot-loader 1s infinite linear alternate;
        animation-delay: 0.5s;
      }
      .dot-loader::before,
      .dot-loader::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
      }
      .dot-loader::before {
        left: -12px;
        width: 7px;
        height: 7px;
        border-radius: 5px;
        background-color: #b9c5cf;
        color: #b9c5cf;
        animation: dot-loader 1s infinite alternate;
        animation-delay: 0s;
      }
      .dot-loader::after {
        left: 12px;
        width: 7px;
        height: 7px;
        border-radius: 5px;
        background-color: #b9c5cf;
        color: #b9c5cf;
        animation: dot-loader 1s infinite alternate;
        animation-delay: 1s;
      }

      @keyframes dot-loader {
        0% {
          background-color: #b9c5cf;
        }
        50%,
        100% {
          background-color: rgba(#b9c5cf, 0.2);
        }
      }
    }
  }
  .user-list-container {
    position: absolute;
    z-index: 5;
    width: 100%;
    top: 100%;
    padding-top: 10px;
    .user-list-box {
      border-radius: 0.6rem;
      background-color: #fff;
      border: 1px solid #ededed;
      box-shadow: 0.1px 0.1px 0.2px rgba(#000, 0.025), 0.7px 0.3px 1px -0.4px rgba(#000, 0.025),
        1.4px 0.6px 1.7px -0.7px rgba(#000, 0.025), 2.3px 1px 2.7px -1.1px rgba(#000, 0.025),
        3.7px 1.6px 4.5px -1.4px rgba(#000, 0.025), 5.7px 2.6px 7.2px -1.7px rgba(#000, 0.025),
        8.9px 3.9px 10.9px -2.1px rgba(#000, 0.025), 13.1px 5.7px 16.1px -2.5px rgba(#000, 0.025);
      .user-item-wrapper {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        padding: 0.8rem 1.1rem;
        background-color: transparent;
        border-bottom: 1px solid #ececec;
        cursor: pointer;
        .detail-wrapper {
          h5 {
            font-family: 'bw_modelicamedium';
            font-size: 0.9rem;
          }
          p {
            font-family: 'bw_modelicaregular';
            font-size: 0.8rem;
          }
        }
        &:hover {
          background-color: #f7f7f7;
        }
        &:first-child {
          border-top-left-radius: 0.6rem;
          border-top-right-radius: 0.6rem;
        }
        &:last-child {
          border-bottom-left-radius: 0.6rem;
          border-bottom-right-radius: 0.6rem;
          border-bottom: none;
        }
      }
      .no-users-note {
        height: 4.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1.1rem;
        background-color: transparent;
        border-bottom: 1px solid #ececec;
        font-family: 'bw_modelicalight';
        font-size: 0.85rem;
        line-height: 1.45rem;
        text-align: center;
      }
    }
  }
}
