.spinner {
  content: '';
  box-sizing: border-box;
  position: absolute;
  border-radius: 50%;
  border: 1.5px solid rgba(0, 0, 0, 0.3);
  border-top-color: rgba(0, 0, 0, 1);
  animation: spinner 0.6s linear infinite;
  &.xxs {
    width: 10px;
    height: 10px;
  }
  &.xs {
    width: 18px;
    height: 18px;
  }
  &.sm {
    width: 30px;
    height: 30px;
  }
  &.md {
    width: 50px;
    height: 50px;
    border-width: 2.5px;
  }
  &.negative {
    border: 1.5px solid rgba(255, 255, 255, 0.3);
    border-top-color: rgba(255, 255, 255, 1);
  }
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
