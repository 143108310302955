@import '~@theme';

.editor-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid #e7e7e9;
  padding: 1rem;
  background-color: #fafafa;
  &.disabled {
    border: none;
  }
  .editor-play-container {
    flex: 1;
    min-height: 10rem;
    padding: 1.5rem 0rem;
    & > * {
      outline: none;
    }
  }
}
