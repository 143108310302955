.calendar-day-item {
  &.weekend-item {
    flex: 1;
    @media (min-width: 1024px) {
      width: 7rem;
    }
    @media (min-width: 1280px) {
      width: 8rem;
    }
    .calendar-day-header {
      .calendar-day {
        .day-items {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          span {
            position: relative;
            &:first-child {
              &::after {
                position: absolute;
                margin: auto;
                bottom: -0.5rem;
                left: 0;
                right: 0;
                content: '';
                height: 1px;
                width: 10px;
                background-color: #b5c1c7;
              }
            }
          }
        }
      }
    }
  }
}
