.select-container {
  position: relative;
  .selected-value {
    cursor: pointer;
  }
  .options-wrapper {
    width: 15rem;
    margin-top: 0.8rem;
    position: absolute;
    z-index: 1;
    left: 0;
    text-align: left;
    border-radius: 0.5rem;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-right: none;
    box-shadow: 0.1px 0.1px 0.2px rgba(#000, 0.1), 0.7px 0.3px 1px -0.4px rgba(#000, 0.1),
      1.4px 0.6px 1.7px -0.7px rgba(#000, 0.1), 2.3px 1px 2.7px -1.1px rgba(#000, 0.1),
      3.7px 1.6px 4.5px -1.4px rgba(#000, 0.1), 5.7px 2.6px 7.2px -1.7px rgba(#000, 0.1),
      8.9px 3.9px 10.9px -2.1px rgba(#000, 0.1), 13.1px 5.7px 16.1px -2.5px rgba(#000, 0.1);
    .search-box {
      padding: 0.7rem 0.6rem;
      input {
        width: 100%;
        height: 1.2rem;
        font-family: 'bw_modelicaregular';
        font-size: 0.8rem;
        border: none;
      }
    }
    .options-list {
      .list-title {
        width: 100%;
        color: #fff;
        padding: 0.35rem 0.6rem 0.3rem;
        background-color: #3b4349;
        font-family: 'bw_modelicabold';
        font-size: 0.55rem;
        text-transform: uppercase;
      }
      ul {
        display: flex;
        flex-direction: column;
        max-height: 15rem;
        overflow: auto;
        li {
          padding: 0.7rem 0.6rem;
          font-family: 'bw_modelicaregular';
          font-size: 0.8rem;
          line-height: 1.05rem;
          border-bottom: 1px solid #f1f1f1;
          cursor: pointer;
          &:hover {
            background-color: #f1f1f1;
          }
          &.active {
            background-color: #f1f1f1;
            cursor: default;
          }
          &:last-child {
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            border-bottom: none;
          }
        }
      }
    }
  }
  &.right {
    .options-wrapper {
      left: auto;
      right: 0;
    }
  }
}
