.meetings-page-container {
  .meetings-calendar-header {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    margin-top: 1rem;
    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 0rem;
      margin-bottom: 2rem;
    }
    & > div {
      display: flex;
      align-items: center;
    }
    .calendar-info-section {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      @media (min-width: 1024px) {
        column-gap: 2rem;
      }
      h3 {
        font-family: 'bw_modelicabold';
        font-size: 1.3rem;
        @media (min-width: 1024px) {
          font-family: 'bw_modelicamedium';
          font-size: 1.7rem;
        }
      }
    }
    .calendar-action-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 2rem;
      @media (min-width: 1024px) {
        display: flex;
        justify-content: flex-end;
      }
      .calendar-actions {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        @media (min-width: 1024px) {
          column-gap: 1rem;
        }
        .action-trigger {
          font-family: 'bw_modelicamedium';
          font-size: 0.7rem;
          border-radius: 0.3rem;
          padding: 0rem 0.5rem;
          height: 1.5rem;
          color: #656565;
          border: 1px solid #b8b5b5;
          background-color: #fff;
          &:hover:not(.disabled) {
            color: #fff;
            background-color: #000;
            border-color: #000;
          }
          &.disabled {
            opacity: 0.5;
            cursor: default;
            color: #656565;
            background-color: #fff;
          }
          &.timeline-switch {
            padding: 0rem 0.4rem;
            @media (min-width: 1024px) {
              padding: 0rem 0.7rem;
            }
          }
          @media (min-width: 1024px) {
            height: 2rem;
            padding: 0rem 1rem;
            font-size: 0.9rem;
            border-radius: 0.4rem;
          }
        }
      }
    }
  }
  .meetings-calendar-data {
    width: 100%;
    padding: 2rem 0;
    overflow-x: auto;
    .data-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 3rem;
      width: 100%;
      @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        column-gap: 1rem;
      }
    }
  }
}
