.home-page-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
  .home-banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    @media (min-width: 1024px) {
      margin-top: 4rem;
    }
    h1 {
      font-family: 'bw_modelicaextrabold';
      font-size: 1.9rem;
      line-height: 2.6rem;
      margin-bottom: 1.3rem;
      @media (min-width: 1024px) {
        font-size: 2.7rem;
        line-height: 3.2rem;
        margin-bottom: 1.5rem;
      }
    }
    p {
      width: 100%;
      font-size: 1rem;
      line-height: 1.4rem;
      margin-bottom: 2.5rem;
      @media (min-width: 1024px) {
        width: 75%;
        font-size: 1.1rem;
        line-height: 1.6rem;
      }
    }
  }
  .home-problems-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .stats-box {
      position: relative;
      margin: 3rem 0 5rem;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      margin-top: 5rem;
      width: 70%;
      @media (min-width: 1024px) {
        margin-top: 2rem;
        flex-direction: column;
        height: 38rem;
        width: 100%;
      }
      @media (min-width: 1280px) {
        height: 38rem;
        width: 80%;
      }
      @media (min-width: 1536px) {
        height: 40rem;
        width: 80%;
      }
      .money-roll-illustration {
        display: none;
        position: relative;
        z-index: -1;
        @media (min-width: 1024px) {
          display: block;
          width: 55%;
        }
        @media (min-width: 1280px) {
          width: 55%;
        }
        @media (min-width: 1536px) {
          width: 60%;
        }
      }
      .stats-list-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 5rem;
        @media (min-width: 700px) {
          width: 70%;
        }
        @media (min-width: 1024px) {
          position: absolute;
          width: 15rem;
          height: 100%;
          row-gap: 0px;
        }
        @media (min-width: 1280px) {
          width: 15rem;
        }
        @media (min-width: 1536px) {
          width: 17rem;
        }
        &:first-of-type {
          left: 0;
        }
        &:last-of-type {
          display: none;
          right: 0;
          @media (min-width: 1024px) {
            display: block;
          }
        }
        .stat-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          &::after {
            content: '';
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: -2.5rem;
            height: 1px;
            width: 15px;
            background-color: #51575c;
            @media (min-width: 1024px) {
              display: none;
            }
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          @media (min-width: 1024px) {
            align-items: flex-start;
            text-align: left;
            padding: 1.5rem 0;
            border-bottom: 1px solid #dbdbdb;
          }
          @media (min-width: 1280px) {
            padding: 1.5rem 0;
          }
          @media (min-width: 1536px) {
            padding: 2rem 0;
          }
          h4 {
            font-family: 'bw_modelicaextrabold';
            font-size: 1.4rem;
            margin-bottom: 0.9rem;
            @media (min-width: 1024px) {
              font-size: 1.2rem;
              margin-bottom: 0.6rem;
            }
          }
          p {
            font-size: 0.95rem;
            line-height: 1.3rem;
            @media (min-width: 1024px) {
              font-size: 0.9rem;
              line-height: 1.2rem;
            }
          }
          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
    p.problem-detail {
      width: 80%;
      font-size: 0.95rem;
      line-height: 1.5rem;
      margin-bottom: 2.5rem;
      @media (min-width: 1024px) {
        width: 60%;
      }
    }
    p.problem-disclaimer-note {
      font-size: 0.8rem;
      line-height: 1.5rem;
      width: 80%;
      color: #6d6d6d;
      @media (min-width: 1024px) {
        width: 60%;
      }
    }
  }
  .home-solution-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    p.solution-description {
      width: 100%;
      font-size: 0.95rem;
      line-height: 1.4rem;
      margin-top: 2rem;
      margin-bottom: 2.5rem;
      @media (min-width: 1024px) {
        width: 70%;
        font-size: 1.1rem;
        line-height: 1.7rem;
        margin-top: 2.5rem;
        margin-bottom: 5rem;
      }
      .meating-logo {
        display: inline-block;
        width: 88px;
        height: 25px;
        background-image: url('~@images/app-logo-dark.png');
        background-size: contain;
        background-repeat: no-repeat;
        text-indent: -9999px;
        @media (min-width: 1024px) {
          width: 100px;
          height: 30px;
        }
      }
    }
    .details-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      row-gap: 3rem;
      margin-bottom: 7rem;
      @media (min-width: 1024px) {
        row-gap: 0px;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 7rem;
      }
      .detail-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        @media (min-width: 700px) {
          width: 70%;
        }
        @media (min-width: 1024px) {
          width: 20%;
          align-items: flex-start;
          text-align: left;
        }
        .index {
          height: 50px;
          width: 50px;
          border-radius: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'bw_modelicaextrabold';
          font-size: 1.2rem;
          background-color: #e5e6e6;
          margin-bottom: 1.3rem;
          @media (min-width: 1024px) {
            font-family: 'bw_modelicabold';
            margin-bottom: 2rem;
          }
        }
        h3 {
          font-family: 'bw_modelicaextrabold';
          font-size: 1.1rem;
          line-height: 1.5rem;
          margin-bottom: 0.5rem;
          @media (min-width: 1024px) {
            height: 4.5rem;
            font-family: 'bw_modelicabold';
            font-size: 1.05rem;
            line-height: 1.4rem;
            margin-bottom: 1rem;
          }
          @media (min-width: 1280px) {
            height: 2.8rem;
            font-size: 1.15rem;
            line-height: 1.5rem;
          }
        }
        p {
          font-size: 0.95rem;
          line-height: 1.3rem;
        }
      }
    }
    .probe-section {
      margin-bottom: 5rem;
      p {
        font-family: 'bw_modelicalight';
        font-size: 1.4rem;
        line-height: 2.1rem;
        @media (min-width: 1024px) {
          font-size: 1.5rem;
        }
      }
      h3 {
        font-family: 'bw_modelicablack';
        font-size: 2rem;
        line-height: 3rem;
        margin: 1.5rem 0;
        @media (min-width: 1024px) {
          font-size: 3rem;
          line-height: 4rem;
          margin: 1.5rem 0;
        }
      }
      .probe-trigger {
        margin-top: 3rem;
      }
    }
    p.solution-text {
      width: 100%;
      font-size: 0.9rem;
      line-height: 1.3rem;
      @media (min-width: 1024px) {
        width: 70%;
        font-size: 1rem;
        line-height: 1.7rem;
      }
    }
    .solution-thanks-ribbon {
      position: relative;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5rem 0 5rem;
      font-family: 'bw_modelicalight';
      font-size: 1.3rem;
      line-height: 2rem;
      @media (min-width: 1024px) {
        width: 100%;
        font-size: 1.7rem;
      }
    }
  }
  .section-title {
    h4 {
      font-family: 'bw_modelicaextrabold';
      font-size: 1.4rem;
      margin-bottom: 0.3rem;
      color: #b5b9bc;
      @media (min-width: 1024px) {
        font-size: 1.8rem;
        margin-bottom: 0.6rem;
      }
    }
    h2 {
      font-family: 'bw_modelicaextrabold';
      font-size: 1.8rem;
      line-height: 2.2rem;
      @media (min-width: 1024px) {
        font-size: 2.3rem;
      }
    }
  }
}
