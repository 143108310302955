.dashboard-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  h3 {
    font-family: 'bw_modelicabold';
    font-size: 1.8rem;
  }
  h2 {
    font-family: 'bw_modelicaextrabold';
    font-size: 4.5rem;
  }
}
