.session-minutes-editor-container {
  border-radius: 0.5rem;
  border: 1px solid #e7e7e9;
  background-color: #fafafa;
  height: 30rem;
  @media (min-width: 1024px) {
    height: 100%;
  }
  .editor-container {
    height: 90%;
    background-color: transparent;
    border: none;
  }
  .minutes-editor-actions {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .data-items {
      display: flex;
      align-items: center;
      & > div {
        position: relative;
        &:after {
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          right: 0;
          content: '';
          height: 1.5rem;
          width: 1px;
          background-color: #dededf;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
