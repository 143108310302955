@import '~@theme';

.setup-page-container {
  width: 100%;
  margin: 10vh 0;
  @media (min-width: 1024px) {
    margin: 5vh 0;
  }
  @media (min-width: 1280px) {
    margin: 12vh 0;
  }
  @media (min-width: 1536px) {
    margin: 12vh 0;
  }
  .form-container {
    width: 100%;
    @media (min-width: 550px) {
      width: 70%;
    }
    @media (min-width: 1024px) {
      width: 60%;
    }
    @media (min-width: 1536px) {
      width: 50%;
    }
    h3 {
      font-family: 'bw_modelicaextrabold';
      font-size: 1.8rem;
      line-height: 2.3rem;
      margin-bottom: 1.5rem;
      @media (min-width: 1024px) {
        font-size: 2rem;
      }
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
      @media (min-width: 1024px) {
        font-size: 1.1rem;
        line-height: 1.7rem;
      }
      &.section-detail {
        font-size: 0.9rem;
        line-height: 1.3rem;
        @media (min-width: 1024px) {
          font-size: 0.97rem;
          line-height: 1.5rem;
        }
      }
    }
    .setup-form-wrapper {
      margin-top: 2rem;
      .section-item {
        .field-item-container {
          margin: 2rem 0;
          .field-item {
            label {
              font-size: 0.9rem;
            }
          }
        }
        .invites-list-container {
          margin: 2rem 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          @media (min-width: 1024px) {
            width: 30rem;
          }
          .invite-item {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            min-height: 3.5rem;
            padding: 1rem;
            border-radius: 0.6rem;
            background: #eef2f3;
            border: 1px solid #dfe4e4;
            @media (min-width: 1024px) {
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
              row-gap: 0px;
            }
            &:hover {
              background: #eceeef;
            }
            h4 {
              width: 67%;
              font-family: 'bw_modelicamedium';
              font-size: 0.95rem;
              line-height: 1.2rem;
              padding-top: 0.2rem;
            }
            .invite-action {
              width: 100%;
              display: flex;
              align-items: center;
              column-gap: 0.6rem;
              @media (min-width: 1024px) {
                width: 33%;
                justify-content: flex-end;
              }
            }
          }
        }
        .invite-processed {
          height: 20px;
          width: 20px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.7rem;
          padding-top: 0.1rem;
          border-radius: 1rem;
          background-color: #0ca223;
        }
      }
      .setup-actions {
        display: flex;
        align-items: center;
        column-gap: 1rem;
      }
    }
    .input-field-container {
      width: 100%;
      @media (min-width: 1024px) {
        width: 30rem;
      }
      .setup-name {
        margin-bottom: 1.6rem;
        width: 100%;
      }
    }
    .workspace-name-field-container {
      position: relative;
      margin-top: 0.7rem;
      height: 2.8rem;
      border-radius: 0.5rem;
      background-color: transparent;
      border: 1px solid #cacccd;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 2.5rem;
      @media (min-width: 1024px) {
        width: 30rem;
      }
      input {
        height: 100%;
        width: 60%;
        padding: 0 1rem;
        background-color: transparent;
        border: none;
        font-family: 'bw_modelicaregular';
        font-size: 0.95rem;
        @media (min-width: 1024px) {
          width: 70%;
        }
      }
      label {
        width: 40%;
        height: 100%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'bw_modelicabold';
        font-size: 0.95rem;
        letter-spacing: 0.3px;
        background: #000;
        @media (min-width: 1024px) {
          width: 30%;
        }
      }
      .status {
        position: absolute;
        top: 125%;
        font-family: 'bw_modelicamedium';
        font-size: 0.75rem;
        letter-spacing: 0.4px;
        &::before {
          font-family: 'icomoon';
          font-size: 8px;
          border-radius: 5px;
          margin-right: 5px;
          padding: 2px 3px;
          border: 1px solid;
        }
        &.available {
          color: #40916c;
          &::before {
            content: '\e906';
            border-color: #40916c;
          }
        }
        &.not-available {
          color: #ef233c;
          &::before {
            content: '\e903';
            border-color: #ef233c;
          }
        }
      }
    }
  }
}
.setup-complete-banner-container {
  display: flex;
  font-family: 'bw_modelicalight';
  font-size: 4rem;
  line-height: 5rem;
  margin-top: 20vh;
  width: min-content;
  @media (min-width: 1024px) {
    font-size: 5rem;
    margin-top: 0px;
    align-items: center;
    width: auto;
  }
}
