$itemWidth: 28rem;

.settings-page-container {
  margin-top: 2rem;
  @media (min-width: 1024px) {
    margin-top: 0rem;
    display: flex;
    flex-direction: row;
    column-gap: 2.5rem;
  }
  @media (min-width: 1280px) {
    column-gap: 3rem;
  }
  .tabs-container {
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 0;
    padding: 1rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0.1px -0.4px 0.5px rgba(#000, 0.05), 0.9px -2.6px 3.1px -0.4px rgba(#000, 0.05),
      1.6px -4.8px 5.7px -0.7px rgba(#000, 0.05), 2.6px -7.9px 9.4px -1.1px rgba(#000, 0.05),
      4.2px -12.6px 14.9px -1.4px rgba(#000, 0.05), 6.6px -19.7px 23.4px -1.8px rgba(#000, 0.05),
      10px -29.9px 35.5px -2.1px rgba(#000, 0.05), 14.7px -44.1px 52.3px -2.5px rgba(#000, 0.05);
    @media (min-width: 1024px) {
      box-shadow: none;
      position: static;
      width: 5rem;
      padding: 0px;
    }
    @media (min-width: 1280px) {
      width: 5.5rem;
    }
    ul {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
      @media (min-width: 1024px) {
        flex-direction: column;
        row-gap: 2rem;
      }
      .tab-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 0.4rem;
        width: 4rem;
        height: 3.8rem;
        border-radius: 0.7rem;
        cursor: pointer;
        color: #9ca5aa;
        border: 1px solid #edf1f3;
        background-color: #f4f4f4;
        @media (min-width: 1024px) {
          row-gap: 0.5rem;
          width: 100%;
          height: 5.5rem;
          border-radius: 1rem;
        }
        @media (min-width: 1280px) {
          height: 5.5rem;
        }
        &:hover {
          background-color: #efefef;
        }
        &.active {
          cursor: default;
          color: #000;
          background-color: #f4f4f4;
        }
        .icon {
          font-size: 1.4rem;
          @media (min-width: 1024px) {
            font-size: 1.6rem;
          }
          @media (min-width: 1280px) {
            font-size: 1.7rem;
          }
        }
        label {
          font-family: 'bw_modelicamedium';
          font-size: 0.7rem;
          @media (min-width: 1024px) {
            font-size: 0.85rem;
          }
          @media (min-width: 1280px) {
            font-size: 0.8rem;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
  .details-container {
    flex: 1;
    .section-item {
      display: flex;
      flex-direction: column;
      row-gap: 4rem;
      section {
        .title-section {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 1rem;
          border-bottom: 1px solid #e7e9ea;
          margin-bottom: 2rem;
          .info-box {
            display: flex;
            align-items: center;
            column-gap: 1rem;
          }
          h4 {
            font-family: 'bw_modelicabold';
            font-size: 1.25rem;
            @media (min-width: 1024px) {
              font-size: 1.15rem;
            }
            @media (min-width: 1280px) {
              font-size: 1.25rem;
            }
          }
        }
        .form-section {
          display: flex;
          column-gap: 3rem;
          row-gap: 3rem;
          flex-wrap: wrap;
          @media (min-width: 1024px) {
            column-gap: 2rem;
          }
          @media (min-width: 1280px) {
            column-gap: 4rem;
          }
          .field-item {
            label {
              font-family: 'bw_modelicabold';
              font-size: 1.05rem;
              @media (min-width: 1024px) {
                font-size: 1rem;
              }
              @media (min-width: 1280px) {
                font-size: 1.05rem;
              }
            }
            p {
              font-size: 0.9rem;
              line-height: 1.2rem;
              margin-top: 0.6rem;
              margin-bottom: 1.3rem;
              color: #3b3e41;
              @media (min-width: 1024px) {
                font-size: 0.85rem;
                line-height: 1.15rem;
              }
              @media (min-width: 1280px) {
                font-size: 0.9rem;
                line-height: 1.2rem;
              }
            }
          }

          .workspace-name-field-container {
            position: relative;
            margin-top: 0.7rem;
            height: 3rem;
            border-radius: 0.5rem;
            background-color: transparent;
            border: 1px solid #cacccd;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            input {
              height: 100%;
              width: 60%;
              padding: 0 1rem;
              background-color: transparent;
              border: none;
              font-family: 'bw_modelicaregular';
              font-size: 0.95rem;
              @media (min-width: 1024px) {
                width: 70%;
              }
            }
            label {
              width: 30%;
              height: 100%;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: 'bw_modelicabold';
              font-size: 0.8rem;
              letter-spacing: 0.3px;
              background: #000000;
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
              @media (min-width: 1024px) {
                font-size: 0.9rem;
                width: 40%;
              }
              @media (min-width: 1280px) {
                font-size: 0.9rem;
                width: 30%;
              }
            }
            .status {
              position: absolute;
              top: 125%;
              font-family: 'bw_modelicamedium';
              font-size: 0.75rem;
              letter-spacing: 0.4px;
              &::before {
                font-family: 'icomoon';
                font-size: 8px;
                border-radius: 5px;
                margin-right: 5px;
                padding: 2px 3px;
                border: 1px solid;
              }
              &.available {
                color: #40916c;
                &::before {
                  content: '\e906';
                  border-color: #40916c;
                }
              }
              &.not-available {
                color: #ef233c;
                &::before {
                  content: '\e903';
                  border-color: #ef233c;
                }
              }
            }
          }
          .members-container {
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;
            .member-item {
              display: flex;
              align-items: center;
              column-gap: 1rem;
              padding: 0.8rem 1.2rem;
              border-radius: 0.6rem;
              background-color: #f5f6f8;
              border: 1px solid #e9ebee;
              .member-details {
                flex: 1;
                display: flex;
                flex-direction: column;
                h4 {
                  font-family: 'bw_modelicabold';
                  font-size: 1rem;
                  margin-bottom: 0.4rem;
                  @media (min-width: 1024px) {
                    font-size: 0.9rem;
                    margin-bottom: 0.35rem;
                  }
                  @media (min-width: 1280px) {
                    font-size: 0.9rem;
                    margin-bottom: 0.4rem;
                  }
                }
                p.timestamp {
                  font-size: 0.75rem;
                  color: #454748;
                  margin-bottom: 0.4rem;
                  @media (min-width: 1024px) {
                    font-size: 0.72rem;
                    margin-bottom: 0.35rem;
                  }
                  @media (min-width: 1280px) {
                    font-size: 0.75rem;
                    margin-bottom: 0.4rem;
                  }
                }
                p.user-email {
                  font-size: 0.85rem;
                  color: #000;
                  @media (min-width: 1024px) {
                    font-size: 0.8rem;
                  }
                  @media (min-width: 1280px) {
                    font-size: 0.85rem;
                  }
                }
              }
              .role-switch {
                font-family: 'bw_modelicabold';
                font-size: 0.8rem;
                width: 4rem;
                height: 1.5rem;
                border-radius: 0.3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                background-color: #000;
                cursor: pointer;
                @media (min-width: 1024px) {
                  font-size: 0.7rem;
                  width: 3.5rem;
                  height: 1.5rem;
                }
                @media (min-width: 1280px) {
                  font-size: 0.7rem;
                  width: 4rem;
                  height: 1.5rem;
                }
              }
            }
            .invited-item {
              display: flex;
              align-items: center;
              column-gap: 1rem;
              padding: 0.8rem 1.2rem;
              border-radius: 0.6rem;
              border: 1px solid #eaeaea;
              background-color: #f8f6f6;
              .member-avatar {
                height: 2.1rem;
                width: 2.1rem;
                border-radius: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 0.8rem;
                background-color: #462cae;
              }
              .member-details {
                flex: 1;
                display: flex;
                flex-direction: column;
                p.user-email {
                  font-family: 'bw_modelicamedium';
                  font-size: 0.95rem;
                  color: #000;
                  margin-bottom: 0.3rem;
                  @media (min-width: 1024px) {
                    font-size: 0.85rem;
                  }
                  @media (min-width: 1280px) {
                    font-size: 0.95rem;
                  }
                }
                p.timestamp {
                  font-size: 0.75rem;
                  color: #454748;
                  @media (min-width: 1024px) {
                    font-size: 0.7rem;
                  }
                  @media (min-width: 1280px) {
                    font-size: 0.75rem;
                  }
                }
              }
              .role-switch {
                font-family: 'bw_modelicabold';
                font-size: 0.8rem;
                width: 4rem;
                height: 1.5rem;
                border-radius: 0.3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                background-color: #000;
                cursor: pointer;
                @media (min-width: 1024px) {
                  font-size: 0.7rem;
                  width: 3.5rem;
                  height: 1.5rem;
                }
                @media (min-width: 1280px) {
                  font-size: 0.7rem;
                  width: 4rem;
                  height: 1.5rem;
                }
              }
            }
          }
          .invites-container {
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;
            .invite-item {
              display: flex;
              align-items: center;
              column-gap: 1rem;
              padding: 0.8rem 1.2rem;
              border-radius: 0.6rem;
              background-color: #f5f6f8;
              border: 1px solid #e9ebee;
              .invite-details {
                flex: 1;
                display: flex;
                flex-direction: column;
                h4 {
                  font-family: 'bw_modelicabold';
                  font-size: 1rem;
                  margin-bottom: 0.4rem;
                  @media (min-width: 1024px) {
                    font-size: 0.9rem;
                    margin-bottom: 0.35rem;
                  }
                  @media (min-width: 1280px) {
                    font-size: 0.9rem;
                    margin-bottom: 0.4rem;
                  }
                }
                p.timestamp {
                  font-size: 0.75rem;
                  color: #454748;
                  margin-bottom: 0.4rem;
                  @media (min-width: 1024px) {
                    font-size: 0.72rem;
                    margin-bottom: 0.35rem;
                  }
                  @media (min-width: 1280px) {
                    font-size: 0.75rem;
                    margin-bottom: 0.4rem;
                  }
                }
              }
              .invite-actions {
                display: flex;
                align-items: center;
                column-gap: 0.6rem;
                .invite-action-item {
                  font-family: 'bw_modelicabold';
                  font-size: 0.8rem;
                  width: 4rem;
                  height: 1.5rem;
                  border-radius: 0.3rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #fff;
                  background-color: #000;
                  cursor: pointer;
                  @media (min-width: 1024px) {
                    font-size: 0.7rem;
                    width: 3.5rem;
                    height: 1.5rem;
                  }
                  @media (min-width: 1280px) {
                    font-size: 0.7rem;
                    width: 4rem;
                    height: 1.5rem;
                  }
                }
              }
            }
          }
          .member-invite-container {
            padding: 1rem;
            border-radius: 0.6rem;
            background-color: #f5f6f8;
            border: 1px solid #e9ebee;
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            height: max-content;
            p {
              font-size: 0.85rem;
              line-height: 1.2rem;
              font-family: 'bw_modelicaregular';
              color: #18191a;
              @media (min-width: 1024px) {
                font-size: 0.8rem;
                line-height: 1.1rem;
              }
              @media (min-width: 1280px) {
                font-size: 0.85rem;
                line-height: 1.2rem;
              }
            }
            .invite-user-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 3rem;
              width: 100%;
              padding: 0 1rem;
              border-radius: 0.5rem;
              border: 1px solid #cacccd;
              background-color: #fff;
              input {
                width: 70%;
                height: 100%;
                font-family: 'bw_modelicaregular';
                font-size: 0.95rem;
                border: none;
                background-color: transparent;
              }
            }
          }
          .table-container {
            display: table;
            width: 100%;
            border-collapse: collapse;
            .row-item {
              display: table-row;
              .col-item {
                display: table-cell;
                padding: 8px;
                border: 1px solid #ddd;
                text-align: left;
              }
            }
          }
          .field-wrapper {
            width: 100%;
            @media (min-width: 1024px) {
              width: 24rem;
            }
            @media (min-width: 1280px) {
              width: 27rem;
            }
            @media (min-width: 1536px) {
              width: 30rem;
            }
          }
        }
      }
    }
  }
}
