.session-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  color: #c9c9c9;
  font-size: 0.9rem;
  background-color: #000;
  overflow: hidden;
  @media (min-width: 1024px) {
    min-height: 35%;
  }
}
