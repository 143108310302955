@import '~@theme';

.input-area-container {
  label {
    display: block;
    margin-bottom: 0.7rem;
    font-size: 0.9rem;
  }
  .input-area-wrapper {
    height: 8rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #fff;
    border: 1px solid #cacccd;
    &.error {
      border-color: $error;
    }
    textarea {
      height: 100%;
      width: 100%;
      padding: 1rem;
      font-family: 'bw_modelicaregular';
      font-size: 0.95rem;
      border: none;
      border-radius: 0.5rem;
      background-color: transparent;
      resize: none;
    }
    &.resizable {
      textarea {
        resize: both;
      }
    }
    &.center {
      input {
        text-align: center;
      }
    }
    &.right {
      input {
        text-align: right;
      }
    }
  }
}
