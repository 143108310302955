.inline-editable-text-container {
  .edit-field-wrapper {
    position: relative;
    .inline-edit-field {
      height: 2.8rem;
      width: 100%;
      border-radius: 0.4rem;
      padding: 0 1rem;
      font-family: 'bw_modelicaregular';
      font-size: 0.95rem;
      background-color: transparent;
      border: 1px solid #cacccd;
    }
    .abort-change {
      position: absolute;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.2rem;
      bottom: -22px;
      right: 0px;
      font-size: 0.6rem;
      padding-left: 0.1rem; // hack to position center
      color: #fff;
      background-color: #ef233c;
      &:hover {
        background-color: #d62136;
      }
    }
  }
}
