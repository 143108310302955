@import '~@theme';

.input-field-container {
  label {
    display: block;
    margin-bottom: 0.7rem;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
  .text-field-wrapper {
    height: 2.8rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: transparent;
    border: 1px solid #cacccd;
    @media (min-width: 1536px) {
      height: 2.8rem;
    }
    &.inline {
      display: inline;
    }
    &.error {
      border-color: $error;
    }
    input {
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      font-family: 'bw_modelicaregular';
      font-size: 0.95rem;
      border: none;
      border-radius: 0.5rem;
      background-color: #fff;
    }
    &.center {
      input {
        text-align: center;
      }
    }
    &.right {
      input {
        text-align: right;
      }
    }
  }
}
