.digest-page-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
  .info-pane {
    width: 100%;
    @media (min-width: 1024px) {
      width: 43%;
    }
    h1 {
      font-family: 'bw_modelicabold';
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
      line-height: 2.7rem;
      @media (min-width: 1024px) {
        font-size: 1.6rem;
      }
    }
    .when {
      margin: 1.2rem 0 0.5rem;
      font-size: 0.9rem;
      .highlight {
        font-family: 'bw_modelicabold';
      }
      @media (min-width: 1024px) {
        margin: 1.2rem 0 1rem;
        font-size: 1.1rem;
      }
    }
    .participants-container {
      label {
        font-family: 'bw_modelicamedium';
        font-size: 0.8rem;
        letter-spacing: 1px;
      }
      .participants-list {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        column-gap: 0.8rem;
        row-gap: 1rem;
      }
    }
  }
  .details-pane {
    width: 100%;
    @media (min-width: 1024px) {
      width: 50%;
    }
  }
}
