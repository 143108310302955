.tab-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  .tab-trigger-container {
    nav {
      ul {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        li {
          position: relative;
          font-family: 'bw_modelicamedium';
          cursor: pointer;
          color: #8b8c8c;
          font-size: 0.6rem;
          line-height: 1.3rem;
          border: 1px solid transparent;
          border-radius: 0.3rem;
          text-transform: uppercase;
          @media (min-width: 1024px) {
            font-size: 0.65rem;
          }
          &.active {
            padding: 0.1rem 0.4rem 0rem;
            color: #000;
            border: 1px solid #000;
          }
          &.inactive {
            cursor: default !important;
          }
          &.disabled {
            cursor: default;
            color: #d0d2d2;
          }
        }
      }
    }
  }
  .tab-section-container {
    flex: 1;
    .tab-data-item {
      height: 100%;
    }
  }
}
