.session-outro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .title {
    font-family: 'bw_modelicaextrabold';
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
    @media (min-width: 1024px) {
      width: 100%;
      font-size: 3.5rem;
      margin-bottom: 3rem;
    }
  }
  p {
    width: 90%;
    font-family: 'bw_modelicalight';
    font-size: 1rem;
    line-height: 1.6rem;
    margin-bottom: 1.6rem;
    @media (min-width: 1024px) {
      width: 50%;
      font-size: 1.5rem;
      line-height: 2.5rem;
      margin-bottom: 3rem;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }
}
