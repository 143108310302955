.no-records-container {
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1.5rem;
  border-radius: 1rem;
  font-size: 6rem;
  color: #dfe4e6;
  background-color: #fbfbfb;
  border: 1px solid #ececec;
  cursor: default;
  user-select: none;
  .icon {
    cursor: default;
  }
  p {
    font-family: 'bw_modelicaextrabold';
    font-size: 1.2rem;
  }
}
