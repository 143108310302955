.refund-page-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 5rem;
  h1 {
    font-family: 'bw_modelicabold';
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  h2 {
    font-family: 'bw_modelicabold';
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  ul {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    list-style: circle;
    margin-left: 1.2rem;
  }
  address {
    line-height: 2rem;
  }
}
