.login-page-container {
  display: flex;
  align-items: center;
  .login-form {
    width: 100%;
    @media (min-width: 550px) {
      width: 70%;
    }
    @media (min-width: 1024px) {
      width: 60%;
    }
    @media (min-width: 1536px) {
      width: 50%;
    }
    h2 {
      font-family: 'bw_modelicaextrabold';
      font-size: 1.8rem;
      line-height: 2.3rem;
      margin-bottom: 1.5rem;
      @media (min-width: 1024px) {
        font-size: 2rem;
      }
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 4rem;
      @media (min-width: 1024px) {
        font-size: 1.1rem;
        line-height: 1.7rem;
      }
    }
    .login-email {
      margin-bottom: 1.6rem;
      width: 100%;
      @media (min-width: 1024px) {
        width: 30rem;
      }
    }
  }
  .auth-complete-banner {
    width: 100%;
    @media (min-width: 550px) {
      width: 80%;
    }
    @media (min-width: 1024px) {
      width: 60%;
    }
    @media (min-width: 1280px) {
      width: 50%;
    }
    @media (min-width: 1536px) {
      width: 50%;
    }
    h3 {
      font-family: 'bw_modelicalight';
      font-size: 1.7rem;
      line-height: 2.4rem;
      margin-bottom: 1rem;
      @media (min-width: 1024px) {
        font-size: 2.3rem;
        line-height: 3rem;
        margin-bottom: 1.5rem;
      }
    }
    p {
      font-family: 'bw_modelicalight';
      font-size: 1rem;
      line-height: 1.6rem;
      width: 100%;
      @media (min-width: 1024px) {
        font-size: 1.2rem;
        line-height: 2rem;
        width: 100%;
      }
    }
  }
}
