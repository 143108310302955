@import '~@theme';

button {
  position: relative;
  user-select: none;
  overflow: hidden;
  &.primary {
    border-radius: 5rem;
    color: #fff;
    background-color: #000;
    border: none;
    cursor: pointer;
    .loader-wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000;
    }
    &:disabled {
      cursor: default !important;
      box-shadow: none !important;
      background-color: #dfe2e4;
    }
    &.secondary-action {
      background-color: #a1abb4;
    }
    &.xs {
      font-family: 'bw_modelicabold';
      font-size: 0.7rem;
      padding: 5px 13px;
      &:hover {
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.11), 1.4px 1.8px 2.6px -0.8px hsla(0deg, 0%, 0%, 0.11),
          3.4px 4.6px 6.4px -1.7px hsla(0deg, 0%, 0%, 0.11), 8.4px 11.2px 15.8px -2.5px hsla(0deg, 0%, 0%, 0.11);
      }
    }
    &.sm {
      font-family: 'bw_modelicabold';
      font-size: 0.8rem;
      padding: 8px 17px;
      &:hover {
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.11), 1.4px 1.8px 2.6px -0.8px hsla(0deg, 0%, 0%, 0.11),
          3.4px 4.6px 6.4px -1.7px hsla(0deg, 0%, 0%, 0.11), 8.4px 11.2px 15.8px -2.5px hsla(0deg, 0%, 0%, 0.11);
      }
    }
    &.md {
      font-family: 'bw_modelicabold';
      font-size: 1rem;
      padding: 8px 20px;
      &:hover {
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.11), 1.4px 1.8px 2.6px -0.8px hsla(0deg, 0%, 0%, 0.11),
          3.4px 4.6px 6.4px -1.7px hsla(0deg, 0%, 0%, 0.11), 8.4px 11.2px 15.8px -2.5px hsla(0deg, 0%, 0%, 0.11);
      }
    }
    &.lg {
      font-family: 'bw_modelicabold';
      font-size: 1.05rem;
      padding: 12px 25px;
      &:hover {
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.09), 2.2px 2.9px 4.1px -0.3px hsla(0deg, 0%, 0%, 0.09),
          4px 5.3px 7.5px -0.6px hsla(0deg, 0%, 0%, 0.09), 6.2px 8.4px 11.7px -0.9px hsla(0deg, 0%, 0%, 0.09),
          9.5px 12.7px 17.8px -1.2px hsla(0deg, 0%, 0%, 0.09), 14.1px 19px 26.6px -1.6px hsla(0deg, 0%, 0%, 0.09),
          20.8px 27.9px 39.2px -1.9px hsla(0deg, 0%, 0%, 0.09), 29.8px 40px 56.1px -2.2px hsla(0deg, 0%, 0%, 0.09),
          41.8px 56.1px 78.7px -2.5px hsla(0deg, 0%, 0%, 0.09);
      }
    }
    &.xl {
      font-family: 'bw_modelicabold';
      font-size: 1.1rem;
      padding: 10px 25px;
      @media (min-width: 1024px) {
        font-size: 1.3rem;
        padding: 15px 35px;
      }
      &:hover {
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.09), 2.2px 2.9px 4.1px -0.3px hsla(0deg, 0%, 0%, 0.09),
          4px 5.3px 7.5px -0.6px hsla(0deg, 0%, 0%, 0.09), 6.2px 8.4px 11.7px -0.9px hsla(0deg, 0%, 0%, 0.09),
          9.5px 12.7px 17.8px -1.2px hsla(0deg, 0%, 0%, 0.09), 14.1px 19px 26.6px -1.6px hsla(0deg, 0%, 0%, 0.09),
          20.8px 27.9px 39.2px -1.9px hsla(0deg, 0%, 0%, 0.09), 29.8px 40px 56.1px -2.2px hsla(0deg, 0%, 0%, 0.09),
          41.8px 56.1px 78.7px -2.5px hsla(0deg, 0%, 0%, 0.09);
      }
    }
    &.flip-shadow {
      &.xs {
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.11), 1.4px 1.8px 2.6px -0.8px hsla(0deg, 0%, 0%, 0.11),
          3.4px 4.6px 6.4px -1.7px hsla(0deg, 0%, 0%, 0.11), 8.4px 11.2px 15.8px -2.5px hsla(0deg, 0%, 0%, 0.11);
      }
      &.sm {
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.11), 1.4px 1.8px 2.6px -0.8px hsla(0deg, 0%, 0%, 0.11),
          3.4px 4.6px 6.4px -1.7px hsla(0deg, 0%, 0%, 0.11), 8.4px 11.2px 15.8px -2.5px hsla(0deg, 0%, 0%, 0.11);
      }
      &.md {
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.11), 1.4px 1.8px 2.6px -0.8px hsla(0deg, 0%, 0%, 0.11),
          3.4px 4.6px 6.4px -1.7px hsla(0deg, 0%, 0%, 0.11), 8.4px 11.2px 15.8px -2.5px hsla(0deg, 0%, 0%, 0.11);
      }
      &.lg {
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.09), 2.2px 2.9px 4.1px -0.3px hsla(0deg, 0%, 0%, 0.09),
          4px 5.3px 7.5px -0.6px hsla(0deg, 0%, 0%, 0.09), 6.2px 8.4px 11.7px -0.9px hsla(0deg, 0%, 0%, 0.09),
          9.5px 12.7px 17.8px -1.2px hsla(0deg, 0%, 0%, 0.09), 14.1px 19px 26.6px -1.6px hsla(0deg, 0%, 0%, 0.09),
          20.8px 27.9px 39.2px -1.9px hsla(0deg, 0%, 0%, 0.09), 29.8px 40px 56.1px -2.2px hsla(0deg, 0%, 0%, 0.09),
          41.8px 56.1px 78.7px -2.5px hsla(0deg, 0%, 0%, 0.09);
      }
      &.xl {
        box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.09), 2.2px 2.9px 4.1px -0.3px hsla(0deg, 0%, 0%, 0.09),
          4px 5.3px 7.5px -0.6px hsla(0deg, 0%, 0%, 0.09), 6.2px 8.4px 11.7px -0.9px hsla(0deg, 0%, 0%, 0.09),
          9.5px 12.7px 17.8px -1.2px hsla(0deg, 0%, 0%, 0.09), 14.1px 19px 26.6px -1.6px hsla(0deg, 0%, 0%, 0.09),
          20.8px 27.9px 39.2px -1.9px hsla(0deg, 0%, 0%, 0.09), 29.8px 40px 56.1px -2.2px hsla(0deg, 0%, 0%, 0.09),
          41.8px 56.1px 78.7px -2.5px hsla(0deg, 0%, 0%, 0.09);
      }
      &:focus {
        box-shadow: none;
      }
      &:active {
        box-shadow: none;
      }
    }
  }
}
