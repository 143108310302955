.session-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .identifiers {
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    .copy-link-trigger {
      height: 1.4rem;
      line-height: 1.3rem;
      background: #eeeeee;
      border: 1px solid #bebebe;
      border-radius: 15px;
      padding: 0 0.7rem;
      color: #838383;
      font-family: 'bw_modelicamedium';
      font-size: 0.65rem;
      display: flex;
      align-items: center;
      column-gap: 0.2rem;
      .icon {
        font-size: 0.7rem;
      }
      &:hover {
        background: #dcdcdc;
      }
    }
  }
  h2 {
    font-family: 'bw_modelicabold';
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
  p.schedule {
    font-family: 'bw_modelicaregular';
    font-size: 1rem;
  }
  .actions {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    .session-end-action-wrapper {
      border-radius: 50px;
      height: 32px;
      user-select: none;
      cursor: default;
      display: flex;
      align-items: center;
      overflow: hidden;
      button {
        font-family: 'bw_modelicabold';
        font-size: 0.8rem;
        color: #fff;
        height: 100%;
        padding: 0 0.7rem;
        border: none;
        &:first-child {
          background: #2d3c47;
          padding-left: 1rem;
          &:hover {
            background: #1e2830;
          }
        }
        &:last-child {
          background: #000;
          padding-right: 0.8rem;
        }
      }
    }
  }
}
