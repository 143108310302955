@import '~@theme';

.new-meeting-page-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  .basics-form-container {
    p {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }
    p.large {
      width: 70%;
      font-size: 0.95rem;
      line-height: 1.5rem;
    }
    .meeting-list-wrapper {
      width: 100%;
      margin-bottom: 3rem;
      .meeting-type-list {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        @media (min-width: 1024px) {
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 1rem;
        }
        .meeting-type-item {
          user-select: none;
          position: relative;
          padding: 0.8rem 1rem;
          border-radius: 0.5rem;
          background-color: #f6f6f6;
          border: 1px solid #e9ebee;
          width: 100%;
          cursor: pointer;
          @media (min-width: 1024px) {
            width: 20rem;
            padding: 1rem;
            border-radius: 0.7rem;
          }
          &:hover {
            background-color: #ebecef;
            border: 1px solid #e2e4e8;
          }
          h4 {
            font-family: 'bw_modelicabold';
            font-size: 0.8rem;
            margin-bottom: 0.3rem;
            @media (min-width: 1024px) {
              font-size: 0.95rem;
            }
          }
          p {
            font-size: 0.75rem;
            line-height: 0.9rem;
            @media (min-width: 1024px) {
              font-size: 0.85rem;
              line-height: 1.1rem;
            }
          }
          &.disabled {
            opacity: 0.6;
            cursor: default;
            background-color: #f7f7f7;
            border: 1px solid #e9ebee;
          }
          &.active {
            background-color: #f7f7f7;
            border: 1px solid #f0f0f0;
            cursor: default;
            &::before {
              content: '';
              position: absolute;
              top: 13px;
              right: 13px;
              height: 8px;
              width: 8px;
              border-radius: 2rem;
              background-color: $themeColor;
            }
          }
        }
      }
    }
    .form-container {
      .form-detail {
        width: 70%;
      }
      .form-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        margin: 2rem 0;
        @media (min-width: 1024px) {
          width: 40%;
        }
        .field-item {
          label {
            font-size: 0.9rem;
            line-height: 1.3rem;
          }
          .field-wrapper {
            margin-top: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 2rem;
            > div {
              width: 100%;
            }
            .short-field {
              width: 50%;
            }
            .input-field-container {
              label {
                display: none;
              }
            }
            &.multi-item-wrapper {
              display: flex;
              justify-content: flex-start;
              column-gap: 1rem;
              .duration-option-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: 0.2rem;
                .title {
                  font-family: 'bw_modelicabold';
                  font-size: 1.2rem;
                }
                .sub {
                  font-family: 'bw_modelicabold';
                  font-size: 0.5rem;
                  letter-spacing: 1px;
                  text-transform: uppercase;
                }
              }
            }
          }
          &.disabled {
            opacity: 0.5;
            cursor: default;
            user-select: none;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      .user-list-wrapper {
        margin-bottom: 2rem;
        display: flex;
        flex-wrap: wrap;
        column-gap: 0.6rem;
        row-gap: 1.2rem;
        .participant-item {
          position: relative;
          user-select: none;
          .remove-item-trigger {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            background-color: rgba(0, 0, 0, 0.5);
            display: none;
          }
          &:hover {
            .remove-item-trigger {
              display: flex;
            }
          }
        }
        .participants-place-holder {
          display: flex;
          align-items: center;
          column-gap: 1rem;
          font-family: 'bw_modelicalight';
          font-size: 0.9rem;
          .placeholder-avatar {
            height: 2.1rem;
            width: 2.1rem;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .form-actions {
    margin: 5rem 0 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
  }
}
