.header-actions-pane {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 3rem;
  nav {
    ul {
      display: flex;
      column-gap: 1.4rem;
      li {
        font-family: 'bw_modelicamedium';
        cursor: pointer;
        font-size: 0.85rem;
        color: #000;
      }
    }
  }
  .user-avatar-wrapper {
    position: relative;
    cursor: pointer;
  }
  .mobile-menu-trigger {
    font-size: 1.3rem;
  }
}
