@import '~@theme';

.editor-button-group {
  display: flex;
  align-items: center;
  column-gap: 0.1rem;
  padding: 0 0.5rem;
  border-right: 1px solid lighten(#000, 10%);
  &:last-of-type {
    border-right: none;
  }
}
