.editor-container {
  > .editor-toolbar-container {
    .editor-button {
      width: 1.7rem;
      height: 1.4rem;
      border-radius: 0.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      cursor: pointer;
      &:hover {
        color: #000;
        background-color: #e2e2e2;
      }
      &.active {
        color: #fff;
        background-color: #fb8500;
      }
    }
  }
}
