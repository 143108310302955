$sdWidth: 98%;
$hdWidth: 95%;
$uhdWidth: 1536px;
$sdPadding: 1.5rem;
$hdPadding: 3rem;
$uhdPadding: 6rem;

// Color Codes
$themeColor: #4afb1e;
$error: #ef233c;
$success: #38b000;

// Dot Pattern Related
$bg-color: #fff;
$dot-color: rgba(#000, 0.4);
$dot-size: 1px;
$dot-space: 22px;

@font-face {
  font-family: 'bw_modelicalight';
  src: url('~@fonts/bwmodelica-light-webfont.woff2') format('woff2'),
    url('~@fonts/bwmodelica-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bw_modelicaregular';
  src: url('~@fonts/bwmodelica-regular-webfont.woff2') format('woff2'),
    url('~@fonts/bwmodelica-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bw_modelicamedium';
  src: url('~@fonts/bwmodelica-medium-webfont.woff2') format('woff2'),
    url('~@fonts/bwmodelica-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bw_modelicabold';
  src: url('~@fonts/bwmodelica-bold-webfont.woff2') format('woff2'),
    url('~@fonts/bwmodelica-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bw_modelicaextrabold';
  src: url('~@fonts/bwmodelica-extrabold-webfont.woff2') format('woff2'),
    url('~@fonts/bwmodelica-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bw_modelicablack';
  src: url('~@fonts/bwmodelica-black-webfont.woff2') format('woff2'),
    url('~@fonts/bwmodelica-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 16px;
  @media (min-width: 1280px) {
    html {
      font-size: 18px;
    }
  }
  @media (min-width: 1536px) {
    html {
      font-size: 22px;
    }
  }
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    font-family: 'bw_modelicaregular';
    color: #000000;
    -webkit-tap-highlight-color: transparent;
    overflow: auto !important;
    background: linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
      linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center, $dot-color;
    background-size: $dot-space $dot-space;
    #root {
      display: flex;
      align-items: center;
      flex-direction: column;
      .app-container {
        position: relative;
        width: 100%;
        min-height: calc(100vh - 12rem); // Calculating based on footer height
        @media (min-width: 768px) {
          min-height: calc(100vh - 10rem);
        }
        @media (min-width: 1024px) {
          width: $sdWidth;
        }
        @media (min-width: 1280px) {
          width: $hdWidth;
        }
        @media (min-width: 1536px) {
          width: $uhdWidth;
        }
      }
    }
  }
}

.ease-element {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

.replace-with-meating-logo-inline {
  display: inline-block;
  width: 88px;
  height: 25px;
  background-image: url('~@images/app-logo-dark.png');
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
  @media (min-width: 1024px) {
    width: 100px;
    height: 30px;
  }
}

.spray-magic {
  -webkit-filter: drop-shadow(3px 3px 0px $themeColor);
  filter: drop-shadow(3px 3px 0px $themeColor);
}

.spray-warn {
  -webkit-filter: drop-shadow(3px 3px 0px #ffd60a);
  filter: drop-shadow(3px 3px 0px #ffd60a);
}

.spray-fire {
  -webkit-filter: drop-shadow(3px 3px 0px #fa7d7d);
  filter: drop-shadow(3px 3px 0px #fa7d7d);
}

.highlighter-text {
  margin: 0 -0.4em;
  padding: 0em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(
    to right,
    rgba($themeColor, 0.1),
    rgba($themeColor, 0.7) 4%,
    rgba($themeColor, 0.8)
  );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.scratch-through {
  position: relative;
  background-image: url('~@images/scratch-overlay-illustration.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

textarea,
input {
  outline: none;
}

button {
  cursor: pointer;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .blur-it {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}

.side-padding {
  padding: 0 $sdPadding;
  @media (min-width: 768px) {
    padding: 0 $hdPadding;
  }
  @media (min-width: 1536px) {
    padding: 0 $uhdPadding;
  }
}

.single-page-container {
  min-height: 35rem;
  //md for tablet potrait
  @media (min-width: 768px) {
    min-height: 70vh;
  }
}
