.footer-pane {
  .footer-meating-typo {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.2rem;
    @media (min-width: 768px) {
      align-items: flex-end;
    }
    .typo-1 {
      font-family: 'bw_modelicabold';
      font-size: 0.75rem;
      color: #b1bdc2;
      margin-bottom: 0.2rem;
      @media (min-width: 768px) {
        margin-bottom: 0px;
        font-size: 0.9rem;
      }
    }
    .typo-2 {
      font-family: 'Playfair Display', serif;
      font-size: 1.3rem;
      @media (min-width: 768px) {
        font-size: 1.6rem;
      }
    }
  }
}
