@font-face {
  font-family: 'icomoon';
  src: url('~@fonts/icomoon.eot?o9lhmh');
  src: url('~@fonts/icomoon.eot?o9lhmh#iefix') format('embedded-opentype'),
    url('~@fonts/icomoon.ttf?o9lhmh') format('truetype'), url('~@fonts/icomoon.svg?o9lhmh#icomoon') format('svg'),
    url('~@fonts/icomoon.woff?o9lhmh') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;

  @apply inline-block;
}

.icon-dollar:before {
  content: '\e916';
}
.icon-mobile-menu:before {
  content: '\e915';
}
.icon-open-box:before {
  content: '\e913';
}
.icon-launch:before {
  content: '\e914';
}
.icon-email-send:before {
  content: '\e912';
}
.icon-arrow-line-left:before {
  content: '\e909';
}
.icon-editor-highlight:before {
  content: '\e90a';
}
.icon-arrow-line-right:before {
  content: '\e90b';
}
.icon-editor-text-h6:before {
  content: '\e90c';
}
.icon-editor-text-h5:before {
  content: '\e90d';
}
.icon-editor-text-h4:before {
  content: '\e90e';
}
.icon-editor-text-h3:before {
  content: '\e90f';
}
.icon-editor-text-h2:before {
  content: '\e910';
}
.icon-editor-text-h1:before {
  content: '\e911';
}
.icon-editor-quote:before {
  content: '\e927';
}
.icon-editor-code-block:before {
  content: '\e92f';
}
.icon-editor-code-line:before {
  content: '\e930';
}
.icon-editor-list-numbered:before {
  content: '\e931';
}
.icon-editor-list-bullet:before {
  content: '\e932';
}
.icon-editor-style-strike:before {
  content: '\e933';
}
.icon-editor-style-underline:before {
  content: '\e934';
}
.icon-editor-style-italic:before {
  content: '\e935';
}
.icon-editor-style-bold:before {
  content: '\e936';
}
.icon-editor-align-justify:before {
  content: '\e937';
}
.icon-editor-align-center:before {
  content: '\e938';
}
.icon-editor-align-right:before {
  content: '\e939';
}
.icon-editor-align-left:before {
  content: '\e93a';
}
.icon-editor-text-t:before {
  content: '\e93b';
}
.icon-copy:before {
  content: '\e900';
}
.icon-arrow-right:before {
  content: '\e901';
}
.icon-arrow-left:before {
  content: '\e902';
}
.icon-close:before {
  content: '\e903';
}
.icon-edit:before {
  content: '\e904';
}
.icon-trash:before {
  content: '\e905';
}
.icon-tick:before {
  content: '\e906';
}
.icon-settings-team:before {
  content: '\e907';
}
.icon-settings-personal:before {
  content: '\e908';
}
