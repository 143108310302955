.checkbox-field-container {
  display: flex;
  column-gap: 0.6rem;
  cursor: pointer;
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 18px;
    height: 18px;
    border: 1px solid currentColor;
    border-radius: 0.15rem;
    transform: translateY(2px);
    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 2px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: #53aa63;
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
  }

  input[type='checkbox']:disabled {
    color: #959495;
    cursor: not-allowed;
  }
}
