.session-participants-items-wrapper {
  .invite-user-box {
    background: #f9f9f9;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    p {
      font-size: 0.8rem;
      color: #989898;
      margin-bottom: 0.8rem;
    }
    .input-field-wrapper {
      height: 2.4rem;
    }
  }
  .participants-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.8rem;
  }
}
