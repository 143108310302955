.calendar-day-item {
  position: relative;
  width: 100%;
  display: flex;
  column-gap: 1.5rem;
  @media (min-width: 1024px) {
    width: 16%;
    flex-direction: column;
  }
  @media (min-width: 1280px) {
    min-height: auto;
    width: 16.5%;
  }
  @media (min-width: 1536px) {
    width: 14rem;
  }
  .calendar-day-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1.3rem;
    @media (min-width: 1024px) {
      align-items: flex-start;
      border-bottom: 3px solid #000;
    }
    .calendar-day {
      text-transform: uppercase;
      color: #000;
      margin-bottom: 0.6rem;
      font-family: 'bw_modelicabold';
      font-size: 1.8rem;
      @media (min-width: 1024px) {
        font-family: 'bw_modelicaregular';
        font-size: 0.6rem;
        letter-spacing: 1px;
        font-size: 0.65rem;
        margin-bottom: 0.5rem;
      }
    }
    .calendar-date {
      font-family: 'bw_modelicamedium';
      font-size: 0.7rem;
      letter-spacing: 1px;
      .suffix {
        font-family: 'bw_modelicamedium';
        color: #666c70;
        padding-left: 0.1rem;
        letter-spacing: 0.5px;
      }
      @media (min-width: 1024px) {
        font-size: 1.6rem;
        letter-spacing: normal;
      }
      @media (min-width: 1280px) {
        font-size: 1.9rem;
      }
    }
  }
  .meeting-item-list {
    flex: 1;
    & > div {
      border-top: 1px solid #d7e4ec;
      @media (min-width: 1024px) {
        border-color: #000000;
      }
      &:first-child {
        border-top: none;
        & > div {
          padding-top: 0rem;
          @media (min-width: 1024px) {
            padding-top: 0.8rem;
          }
        }
      }
    }
  }
  &.today {
    .calendar-day-header {
      color: #f4750d;
      border-color: #f4750d;
      .calendar-day {
        color: #f4750d;
      }
    }
  }
}
