@import '~@theme';

.item-with-tooltip {
  position: relative;
  cursor: pointer;
  .tooltip-message {
    width: max-content;
    max-width: 15rem;
    position: absolute;
    padding: 0.25rem 0.5rem;
    border-radius: 0.3rem;
    font-family: 'bw_modelicabold';
    font-size: 0.7rem;
    line-height: 1rem;
    color: #fff;
    background-color: rgba(darken(#000, 9%), 0.8);
    border: 0.5px solid darken(#000, 2%);
    -webkit-box-shadow: 0px 2px 6px -1px rgba(5, 7, 10, 0.7);
    -moz-box-shadow: 0px 2px 6px -1px rgba(5, 7, 10, 0.7);
    box-shadow: 0px 2px 6px -1px rgba(5, 7, 10, 0.7);
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
    @media (min-width: 768px) {
      max-width: 20rem;
    }
    &.left {
      left: -5px;
    }
    &.right {
      right: -5px;
    }
  }
}
