.session-page-container {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
  .session-left-lane {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    @media (min-width: 1024px) {
      width: 40%;
    }
    .session-details-container {
      flex: 1;
    }
  }
  .session-right-lane {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    @media (min-width: 1024px) {
      width: 55%;
      height: 62rem;
    }
    .session-activity-container {
      flex: 1;
    }
  }
}
