.duration-selector {
  position: relative;
  height: 3.4rem;
  width: 17rem !important;
  background-color: #f6f6f6;
  border: 1px solid #e9ebee;
  border-radius: 0.4rem;
  cursor: pointer;
  user-select: none;
  .selected-value {
    height: 100%;
    width: 100%;
    font-family: 'bw_modelicabold';
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
  .options-list {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    top: calc(100% + 1rem);
    border-radius: 0.4rem;
    width: 100%;
    max-height: 15rem;
    font-family: 'bw_modelicamedium';
    font-size: 0.85rem;
    overflow-y: auto;
    border: 1px solid #ededed;
    border-right: none;
    box-shadow: 0.1px 0.1px 0.2px rgba(#000, 0.025), 0.7px 0.3px 1px -0.4px rgba(#000, 0.025),
      1.4px 0.6px 1.7px -0.7px rgba(#000, 0.025), 2.3px 1px 2.7px -1.1px rgba(#000, 0.025),
      3.7px 1.6px 4.5px -1.4px rgba(#000, 0.025), 5.7px 2.6px 7.2px -1.7px rgba(#000, 0.025),
      8.9px 3.9px 10.9px -2.1px rgba(#000, 0.025), 13.1px 5.7px 16.1px -2.5px rgba(#000, 0.025);
    .option-item {
      padding: 0.8rem 1rem;
      display: flex;
      align-items: center;
      &:hover {
        background-color: #f6f6f6;
      }
      &.active {
        background-color: #ededed;
      }
    }
  }
}
