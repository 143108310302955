.date-picker-field-container {
  position: relative;
  height: 2.8rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: #fff;
  border: 1px solid #cacccd;
  padding: 0 1rem;
  user-select: none;
  .selected-value {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.placeholder {
      color: #808080;
    }
  }
  .date-picker-wrapper {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: -1px;
    padding: 1rem;
    margin-top: 10px;
    border-radius: 0.6rem;
    background-color: #fff;
    border: 1px solid #ededed;
    box-shadow: 0.1px 0.1px 0.2px rgba(#000, 0.025), 0.7px 0.3px 1px -0.4px rgba(#000, 0.025),
      1.4px 0.6px 1.7px -0.7px rgba(#000, 0.025), 2.3px 1px 2.7px -1.1px rgba(#000, 0.025),
      3.7px 1.6px 4.5px -1.4px rgba(#000, 0.025), 5.7px 2.6px 7.2px -1.7px rgba(#000, 0.025),
      8.9px 3.9px 10.9px -2.1px rgba(#000, 0.025), 13.1px 5.7px 16.1px -2.5px rgba(#000, 0.025);
    .switch-action {
      position: absolute;
      font-size: 0.75rem;
      top: 1rem;
      cursor: pointer;
      color: #000;
      &.disabled {
        color: #c2c2c2;
        cursor: not-allowed;
      }
      &:first-child {
        left: 1.5rem;
      }
      &:last-child {
        right: 1.5rem;
      }
    }
    .month-wrapper {
      display: flex;
      column-gap: 2rem;
      .month-box {
        width: 225px;
        text-align: center;
        display: flex;
        flex-direction: column;
        h4 {
          font-family: 'bw_modelicamedium';
          font-size: 0.75rem;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin-bottom: 1rem;
        }
        .day-markers {
          width: 100%;
          font-family: 'bw_modelicamedium';
          font-size: 0.7rem;
          text-transform: uppercase;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.8rem;
          .day-item {
            width: 14.285%;
            color: #06a218;
          }
        }
        .date-markers {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          row-gap: 0.5rem;
          .date-item {
            width: 14.285%;
            padding: 5px 0;
            border-radius: 5px;
            font-size: 0.8rem;
            cursor: pointer;
            &:hover:not(.disabled):not(.selected):not(.today) {
              background-color: #e6e4e4;
            }
            &.disabled {
              color: #b0b0b0;
              cursor: not-allowed;
            }
            &.today {
              background-color: #b8f6be;
            }
            &.selected {
              font-family: 'bw_modelicamedium';
              color: #fff;
              background-color: #09c41f;
            }
          }
        }
      }
    }
  }
}
