.avatar-wrapper {
  border-radius: 50%;
  background-color: rgb(217, 228, 237);
  > div {
    height: 100%;
    width: 100%;
  }
  .avatar-image-container {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  .avatar-initials-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'bw_modelicabold';
    font-size: 0.6rem;
    color: #fff;
    border-radius: 50%;
    background-image: linear-gradient(to top, #adb6bb 0%, #cfd9df 100%);
  }
  &.xs {
    height: 1.8rem;
    width: 1.8rem;
    .avatar-initials-wrapper {
      padding-top: 0.1rem;
      font-family: 'bw_modelicaextrabold';
      font-size: 0.6rem;
    }
  }
  &.sm {
    height: 2.1rem;
    width: 2.1rem;
    .avatar-initials-wrapper {
      padding-top: 0.1rem;
    }
  }
  &.md {
    height: 3.1rem;
    width: 3.1rem;
    .avatar-initials-wrapper {
      padding-top: 0.1rem;
    }
  }
  &.lg {
    height: 4.5rem;
    width: 4.5rem;
    .avatar-initials-wrapper {
      padding-top: 0.1rem;
    }
  }
}
