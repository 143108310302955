.option-selector-container {
  height: 3.4rem;
  display: flex;
  width: max-content !important;
  background-color: #f6f6f6;
  border: 1px solid #e9ebee;
  border-radius: 0.4rem;
  overflow: hidden;
  .option-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.8rem;
    border-right: 1px solid #e7e6e6;
    cursor: pointer;
    user-select: none;
    &:last-child {
      border-right: none;
    }
    &.active {
      cursor: default;
      color: #fff;
      background-color: #35b05c;
      -webkit-box-shadow: inset 2px 21px 26px -20px rgba(#000, 0.75);
      -moz-box-shadow: inset 2px 21px 26px -20px rgba(#000, 0.75);
      box-shadow: inset 2px 21px 26px -20px rgba(#000, 0.75);
    }
  }
  &.disabled {
    opacity: 0.5;
    .option-item {
      cursor: default;
    }
  }
}
