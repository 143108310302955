.timer {
  border-radius: 50px;
  height: 32px;
  padding: 0 0.8rem;
  user-select: none;
  cursor: default;
  display: flex;
  align-items: center;
  &.open {
    font-family: 'bw_modelicamedium';
    font-size: 0.8rem;
    color: #6c757d;
    background: #e9ecef;
    border: 1px solid #dee2e6;
  }
  &.relaxed {
    width: 4.8rem;
    font-family: 'bw_modelicaextrabold';
    font-size: 1rem;
    letter-spacing: 1px;
    color: #fff;
    background: #43985f;
    border: 1px solid #398352;
  }
  &.alert {
    width: 4.8rem;
    font-family: 'bw_modelicaextrabold';
    font-size: 1rem;
    letter-spacing: 1px;
    color: #b68e04;
    background: #ffeaa2;
    border: 1px solid #fad34d;
  }
  &.caution {
    width: 4.8rem;
    font-family: 'bw_modelicaextrabold';
    font-size: 1rem;
    letter-spacing: 1px;
    color: #fff;
    background: #f7b212;
    border: 1px solid #dea011;
  }
  &.urgent {
    width: 4.8rem;
    font-family: 'bw_modelicaextrabold';
    font-size: 1rem;
    letter-spacing: 1px;
    color: #fff;
    background: #f77212;
    border: 1px solid #d36110;
  }
  &.critical {
    width: 4.8rem;
    font-family: 'bw_modelicaextrabold';
    font-size: 1rem;
    letter-spacing: 1px;
    color: #fff;
    background: #fa2727;
    border: 1px solid #dc2121;
  }
  &.closed {
    width: 4.8rem;
    font-family: 'bw_modelicaextrabold';
    font-size: 1rem;
    letter-spacing: 1px;
    color: #fff;
    background: #f10707;
    border: 1px solid #d90606;
  }
}
