.footer-pane {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  nav {
    ul {
      display: flex;
      column-gap: 2rem;
      li {
        font-size: 0.9rem;
        cursor: pointer;
      }
    }
  }
  .copyright {
    font-family: 'bw_modelicaregular';
    font-size: 0.7rem;
    color: #000;
    @media (min-width: 768px) {
      font-size: 0.9rem;
    }
  }
}
