.new-meeting-page-container {
  .new-meeting-header-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1rem;
    @media (min-width: 1024px) {
      margin-bottom: 3rem;
    }
    .new-meeting-header-container {
      width: 100%;
      @media (min-width: 1024px) {
        width: 80%;
      }
      h2 {
        font-family: 'bw_modelicaextrabold';
        font-size: 1.5rem;
        line-height: 2.3rem;
        margin-bottom: 0.3rem;
        @media (min-width: 1024px) {
          font-size: 2rem;
          margin-bottom: 1rem;
        }
      }
      p {
        font-size: 0.8rem;
        line-height: 1.2rem;
        @media (min-width: 1024px) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}
