.workspaces-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  > h3 {
    font-family: 'bw_modelicaextrabold';
    font-size: 2rem;
    margin-bottom: 1.3rem;
    text-align: center;
  }
  > p {
    width: 80%;
    font-family: 'bw_modelicalight';
    font-size: 1.1rem;
    text-align: center;
  }
  .workspace-list-container {
    margin-top: 4rem;
    ul {
      li.workspace-item {
        width: 25rem;
        padding: 1rem 1.5rem;
        border-radius: 0.6rem;
        border: 1px solid #d9dce0;
        margin-bottom: 1.1rem;
        cursor: pointer;
        background-color: #fff;
        h5 {
          margin-bottom: 0.4rem;
          font-family: 'bw_modelicamedium';
          font-size: 1rem;
        }
        p {
          font-family: 'bw_modelicalight';
          font-size: 0.8rem;
          letter-spacing: 0.5px;
        }
        &:last-child {
          margin-bottom: 0rem;
        }
        &:hover:not(.active) {
          border-color: #000;
        }
        &.active {
          background-color: #f4f6f9;
        }
      }
    }
  }
}
