.user-context-menu-container {
  position: absolute;
  z-index: 100;
  top: 150%;
  right: 0;
  cursor: default;
  .user-context-menu-wrapper {
    height: max-content;
    width: 10rem;
    color: #ffffff;
    padding: 0.7rem 1rem;
    border-radius: 0.5rem;
    background: -webkit-linear-gradient(90deg, rgba(14, 21, 30, 0.78), rgba(14, 21, 30, 1));
    background: linear-gradient(30deg, rgba(14, 21, 30, 0.78), rgba(14, 21, 30, 1));
    border: 1px solid rgba(27, 42, 60, 0.7);
    box-shadow: 0.1px 0.3px 0.4px hsla(0deg, 0%, 0%, 0.09), 0.3px 1.7px 1.9px -0.4px hsla(0deg, 0%, 0%, 0.09),
      0.6px 3.1px 3.6px -0.7px hsla(0deg, 0%, 0%, 0.09), 1px 5.1px 5.8px -1.1px hsla(0deg, 0%, 0%, 0.09),
      1.6px 8.1px 9.3px -1.4px hsla(0deg, 0%, 0%, 0.09), 2.5px 12.7px 14.6px -1.8px hsla(0deg, 0%, 0%, 0.09),
      3.7px 19.3px 22.1px -2.1px hsla(0deg, 0%, 0%, 0.09), 5.5px 28.5px 32.7px -2.5px hsla(0deg, 0%, 0%, 0.09);
    .greetings {
      font-family: 'bw_modelicamedium';
      font-size: 0.8rem;
      padding-bottom: 0.6rem;
      border-bottom: 1px solid #6d6d6d;
    }
    nav {
      padding-top: 1rem;
      ul {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        li {
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
