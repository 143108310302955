.session-minutes-items-wrapper {
  width: 100%;
  max-height: 43rem;
  padding-bottom: 2rem;
  overflow: auto;
  .minutes-list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}
