.switch {
  display: block;
  position: relative;
  width: 100px;
  height: 70px;
  margin: 70px auto;
  border-radius: 50px;
  background: linear-gradient(to right, #e6e3da, #fff);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 7px 0 0 #fdfdfd, 2px 0 3px rgba(170, 160, 140, 0.3);
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -10px;
    right: -10px;
    z-index: -1;
    background: #f2f1ed;
    border-radius: inherit;
    box-shadow: 1px 0 1px rgba(170, 160, 140, 0.2), 3px 0 3px rgba(170, 160, 140, 0.4),
      inset 1px 0 0 rgba(255, 255, 255, 0.8), 0 0 5px rgba(170, 160, 140, 0.5);
  }
  &:after {
    content: '';
    position: absolute;
    width: 70px;
    height: 60px;
    border-radius: 50%;
    z-index: -1;
    left: 10px;
    top: 18px;
    background: linear-gradient(250deg, rgba(170, 160, 140, 0.7), rgba(170, 160, 140, 0));
    -webkit-filter: blur(1px);
  }
}

#switch {
  clip: rect(0 0 0 0);
  position: absolute;
  visibility: hidden;
  &:checked ~ .switch {
    background: linear-gradient(to right, #f7f6f4, #fff);
    box-shadow: inset -5px 0 0 #dbd3c8, 6px 0 5px rgba(170, 160, 140, 0.75), 16px 0 5px rgba(170, 160, 140, 0.3);
  }
  &:checked ~ .switch:after {
    display: none;
  }
}
