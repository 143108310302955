header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  .header-pane {
    width: 50%;
  }
  height: 5rem;
  @media (min-width: 768px) {
    height: 6rem;
    margin-bottom: 0rem;
  }
  @media (min-width: 1024px) {
    height: 8rem;
  }
}
