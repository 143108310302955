.new-meeting-page-container {
  .agenda-form-container {
    display: flex;
    flex-direction: column;
    position: relative;
    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }
    .form-wrapper {
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;
      margin: 1rem 0;
      @media (min-width: 1024px) {
        width: 45%;
        position: sticky;
      }
      @media (min-width: 1536px) {
        width: 40%;
      }
      .field-item {
        label {
          font-size: 0.9rem;
          line-height: 1.3rem;
        }
        .user-search-field-container {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 2rem;
          > div {
            width: 100%;
          }
          .input-field-container {
            label {
              display: none;
            }
          }
        }
        &.disabled {
          opacity: 0.5;
          cursor: default;
          user-select: none;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        .lead-card-container {
          display: flex;
          align-items: center;
          column-gap: 1rem;
          padding: 0.6rem 1rem;
          border-radius: 0.5rem;
          border: 1px solid #ebedef;
          background-color: #f5f5f7;
        }
      }
      .agenda-actions {
        display: flex;
        justify-content: flex-end;
      }
    }
    .agenda-list-wrapper {
      width: 100%;
      @media (min-width: 1024px) {
        width: 45%;
      }
    }
  }
}
