@import '~@theme';

.rte-container {
  font-size: 0.9rem;
  line-height: 1.4rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'bw_modelicabold';
    line-height: normal;
  }
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.6rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.3rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  h6 {
    font-size: 1rem;
  }
  code {
    border: 1px solid lighten(#000, 20%);
    background-color: darken(#000, 2%);
    padding: 0.2rem 0.4rem;
    border-radius: 0.3rem;
    color: #fff;
    font-family: 'bw_modelicamedium';
    font-size: 80%;
  }
  mark {
    background-color: #4afb1e;
  }
  blockquote {
    padding: 0.4rem 0rem 0.4rem 1rem;
    background-color: rgba(#fb5607, 0.1);
    border-left: 2px solid #fb5607;
    margin: 0.5rem 0;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
}
