.calendar-meeting-item {
  position: relative;
  padding: 0.8rem 0 1.5rem;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 1024px) {
    padding: 0.8rem 0 2rem;
  }
  .timeline {
    font-family: 'bw_modelicaregular';
    font-size: 0.8rem;
    color: #84898e;
    margin-bottom: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .duration {
      font-family: 'bw_modelicabold';
      font-size: 0.55rem;
      color: #fff;
      background-color: #f4845f;
      text-transform: uppercase;
      padding: 0.2rem;
      padding-top: 0.3rem;
      border-radius: 0.2rem;
    }
  }
  .title {
    font-family: 'bw_modelicabold';
    font-size: 0.9rem;
    line-height: 1.1rem;
    color: #000;
    margin-bottom: 1.2rem;
    @media (min-width: 1024px) {
      font-size: 0.9rem;
      line-height: 1rem;
    }
    @media (min-width: 1280px) {
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }
  .participants {
    font-family: 'bw_modelicaregular';
    font-size: 0.8rem;
    color: #f25c54;
  }
  .action-items {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    span {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #95d5b2;
      border: 1px solid #74c69d;
    }
  }
}
