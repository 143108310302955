.session-minute-item-container {
  position: relative;
  background: #f9f9f9;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  padding: 1rem;
  .minute-info-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .time {
      font-size: 0.8rem;
    }
  }
}
