.lost-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 5rem;
  @media (min-width: 1024px) {
    margin-bottom: 0px;
  }
  h3 {
    position: relative;
    font-family: 'bw_modelicaextrabold';
    font-size: 3.5rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
    display: inline-block;
    .glitch {
      position: absolute;
      z-index: -1;
      left: 0;
      &.glitch-1 {
        color: red;
        animation: glitch-1 1s infinite;
      }
      &.glitch-2 {
        color: blue;
        animation: glitch-2 2s infinite;
      }
    }
    @media (min-width: 1024px) {
      font-size: 5rem;
      line-height: 2.7rem;
      margin-bottom: 3rem;
    }
    @media (min-width: 1280px) {
      font-size: 7rem;
      line-height: 2.7rem;
      margin-bottom: 5rem;
    }
  }
  p {
    font-family: 'bw_modelicalight';
    font-size: 1.1rem;
    line-height: 1.7rem;
    width: 100%;
    margin-bottom: 2rem;
    @media (min-width: 1024px) {
      font-size: 1.1rem;
      line-height: 1.5rem;
      width: 60%;
    }
    @media (min-width: 1280px) {
      font-size: 1.3rem;
      line-height: 2rem;
      width: 50%;
    }
  }

  @keyframes glitch-1 {
    0% {
      transform: translate(0);
    }
    25% {
      transform: translate(3px, 0px);
    }
    50% {
      transform: translate(-3px, 0px);
    }
    75% {
      transform: translate(3px, 0px);
    }
    100% {
      transform: translate(0);
    }
  }

  @keyframes glitch-2 {
    0% {
      transform: translate(0);
    }
    25% {
      transform: translate(-3px, 0px);
    }
    50% {
      transform: translate(3px, 0px);
    }
    75% {
      transform: translate(-3px, 0px);
    }
    100% {
      transform: translate(0);
    }
  }
}
