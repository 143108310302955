.loading-container {
  width: 100%;
}

.loading-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px; /* Increased vertical spacing between sections */
  &:last-child {
    margin-bottom: 0px;
  }
}

.loading-block {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite linear;
  border-radius: 4px;
  margin-bottom: 1.3rem; /* Increased vertical spacing between elements */
}

.loading-block.title {
  width: 70%;
  height: 24px;
}

.loading-block.label {
  width: 50%;
  height: 16px;
}

.loading-block.field {
  width: 80%;
  height: 20px;
}

.loading-block.button {
  width: 30%;
  height: 24px;
  border-radius: 12px; /* Fully rounded edges */
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
