.minutes-message-type-wrapper {
  position: relative;
  width: 7.5rem;
  .type-list {
    position: absolute;
    bottom: 100%;
    padding-bottom: 1rem;
    .options-wrapper {
      width: 20rem;
      border-radius: 0.6rem;
      border: 1px solid #f2f1f1;
      background-color: #fff;
      height: 20rem;
      overflow: auto;
      box-shadow: 0.4px 0.6px 0.8px hsla(0deg, 0%, 0%, 0.11), 1.4px 1.8px 2.6px -0.8px hsla(0deg, 0%, 0%, 0.11),
        3.4px 4.6px 6.4px -1.7px hsla(0deg, 0%, 0%, 0.11), 8.4px 11.2px 15.8px -2.5px hsla(0deg, 0%, 0%, 0.11);
      ul {
        li {
          padding: 0.8rem;
          display: flex;
          flex-direction: column;
          row-gap: 0.3rem;
          border-bottom: 1px solid #f2f1f1;
          cursor: pointer;
          &:hover {
            background-color: #f5f6f6;
          }
          &:last-child {
            margin-bottom: 0;
            border-bottom: none;
          }
          &.active {
            background-color: #edeeef;
          }
          label {
            font-family: 'bw_modelicamedium';
            font-size: 0.85rem;
            padding-left: 0.8rem;
            position: relative;
            .color-code {
              position: absolute;
              margin: auto;
              top: 0;
              bottom: 0;
              left: 0;
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background-color: #000;
            }
          }
          p {
            font-size: 0.75rem;
            line-height: 0.9rem;
            padding-left: 0.8rem;
            letter-spacing: 0.3px;
          }
        }
      }
    }
  }
  .type-item {
    position: relative;
    font-family: 'bw_modelicaregular';
    font-size: 0.85rem;
    padding-left: 1rem;
    cursor: pointer;
    .color-code {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0.15rem;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background-color: #000;
    }
  }
}
