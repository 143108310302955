@import '~@theme';

.notification-container {
  position: fixed;
  z-index: 5;
  top: 4rem;
  margin-right: $sdPadding;
  @media (min-width: 768px) {
    margin-right: $hdPadding;
  }
  @media (min-width: 1536px) {
    margin-right: $uhdPadding;
  }
  .notification-item {
    min-height: 4rem;
    width: 18rem;
    padding: 0.5rem 1rem;
    border-radius: 0.8rem;
    border: 1px solid rgb(49, 60, 64);
    color: #fff;
    background-color: rgba(#3a4a55, 0.9);
    box-shadow: 0px 0.3px 0.4px hsla(0deg, 0%, 0%, 0.04), 0.2px 1.7px 2.3px -0.2px hsla(0deg, 0%, 0%, 0.01),
      0.3px 2.9px 3.8px -0.3px hsla(0deg, 0%, 0%, 0.05), 0.5px 4.5px 6px -0.5px hsla(0deg, 0%, 0%, 0.015),
      0.7px 6.5px 8.6px -0.7px hsla(0deg, 0%, 0%, 0.07), 1px 9.5px 12.6px -0.9px hsla(0deg, 0%, 0%, 0.02),
      1.5px 13.7px 18.2px -1px hsla(0deg, 0%, 0%, 0.09), 2.1px 19.5px 25.9px -1.2px hsla(0deg, 0%, 0%, 0.025);
    h4 {
      font-family: 'bw_modelicabold';
      font-size: 0.9rem;
      line-height: 1.3rem;
      margin-bottom: 0.4rem;
      padding-bottom: 0.3rem;
      border-bottom: 1px solid rgba(#fff, 0.5);
    }
    p {
      font-family: 'bw_modelicabold';
      font-size: 0.9rem;
      line-height: 1.2rem;
      padding-right: 5%;
      padding-bottom: 0.5rem;
    }
    &.error {
      border: 1px solid rgba(#d90429, 1);
      background-color: rgba(#d90429, 0.9);
    }
    &.success {
      border: 1px solid rgba(#2b9348, 1);
      background-color: rgba(#2b9348, 0.9);
    }
  }
}
