.mobile-menu-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  .mobile-menu-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    height: 100%;
    width: 80%;
    background-color: #fff;
    padding: 2rem 0rem;
    box-shadow: -0.05px 0.05px 0.4px rgba(0, 0, 0, 0.05), -1.6px 0.5px 1.9px -0.4px rgba(0, 0, 0, 0.05),
      -2.9px 1px 3.5px -0.7px rgba(0, 0, 0, 0.05), -4.8px 1.6px 5.7px -1.1px rgba(0, 0, 0, 0.05),
      -7.7px 2.6px 9.1px -1.4px rgba(0, 0, 0, 0.05), -12px 4px 14.2px -1.8px rgba(0, 0, 0, 0.05),
      -18.3px 6.1px 21.7px -2.1px rgba(0, 0, 0, 0.05), -27px 9px 32px -2.5px rgba(0, 0, 0, 0.05);
    .team-switcher-wrapper {
      padding: 0rem 1.3rem;
    }
    .user-container {
      display: flex;
      align-items: center;
      column-gap: 0.6rem;
      padding: 0rem 1.3rem;
      .details-wrapper {
        width: 80%;
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;
        .name {
          font-family: 'bw_modelicamedium';
          font-size: 0.8rem;
        }
        .email {
          font-size: 0.8rem;
        }
      }
    }
    .menu-wrapper {
      flex: 1;
      ul {
        li {
          height: 3rem;
          padding: 0rem 1.3rem;
          display: flex;
          align-items: center;
          font-family: 'bw_modelicaregular';
          font-size: 0.9rem;
        }
      }
    }
  }
}
