@import '~@theme';

.editor-container {
  > .editor-toolbar-container {
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.4rem 0;
    border: 1px solid #e3e3e3;
    background-color: #F2F2F2;
  }
  &.for-block {
    > .editor-toolbar-container {
      border-radius: 0rem;
      border-top: 1px solid lighten(#000, 10%);
    }
  }
}
