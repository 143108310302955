.session-intro-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .title {
    width: 90%;
    font-family: 'bw_modelicaextrabold';
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
    @media (min-width: 1024px) {
      width: 100%;
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }
    @media (min-width: 1280px) {
      font-size: 2.8rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 1536px) {
      font-size: 3.5rem;
      margin-bottom: 3rem;
    }
  }
  .name {
    width: 60%;
    font-family: 'bw_modelicamedium';
    font-size: 1rem;
    line-height: 1.8rem;
    margin-bottom: 1.6rem;
    @media (min-width: 1024px) {
      font-size: 1.5rem;
      line-height: 2.5rem;
      margin-bottom: 1.6rem;
    }
    @media (min-width: 1280px) {
      font-size: 1.5rem;
      line-height: 2.5rem;
      margin-bottom: 2rem;
    }
    @media (min-width: 1536px) {
      font-size: 1.5rem;
      line-height: 2.5rem;
      margin-bottom: 3rem;
    }
  }
  .schedule {
    font-family: 'bw_modelicalight';
    font-size: 1.2rem;
    @media (min-width: 1024px) {
      font-size: 1.8rem;
    }
    @media (min-width: 1280px) {
      font-size: 1.8rem;
    }
    @media (min-width: 1536px) {
      font-size: 2.2rem;
    }
  }
  .action {
    border-radius: 5rem;
    margin-top: 3rem;
    overflow: hidden;
    @media (min-width: 1024px) {
      margin-top: 2.5rem;
    }
    @media (min-width: 1280px) {
      margin-top: 3rem;
    }
    @media (min-width: 1536px) {
      margin-top: 5rem;
    }
    .notice {
      font-size: 1.2rem;
    }
    .action-item {
      border: none;
      font-family: 'bw_modelicabold';
      font-size: 0.85rem;
      padding: 8px 18px;
      @media (min-width: 1024px) {
        font-size: 1.3rem;
        padding: 15px 35px;
      }
      color: #fff;
      background-color: #000;
      &:first-child {
        background-color: #415161;
        padding-right: 10px;
        @media (min-width: 1024px) {
          padding-right: 20px;
        }
        &:hover {
          background-color: #29333d;
        }
      }
      &:last-child {
        padding-left: 10px;
        background-color: #232c35;
        @media (min-width: 1024px) {
          padding-left: 20px;
        }
        &:hover {
          background-color: #000;
        }
      }
    }
  }
  .silent-action {
    position: absolute;
    bottom: 0;
  }
}
