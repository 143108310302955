.silent-day-note {
  flex: 1;
  @media (min-width: 1024px) {
    padding-top: 1rem;
  }
  .note-wrapper {
    width: 100%;
    height: 7rem;
    padding: 0.7rem;
    border-radius: 0.6rem;
    display: flex;
    align-items: flex-end;
    background-color: #f6f6f6;
    p {
      width: 70%;
      font-family: 'bw_modelicaregular';
      font-size: 0.7rem;
      line-height: 0.95rem;
      color: #a9b0b2;
    }
  }
}
