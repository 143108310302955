.type {
  border-radius: 3px;
  font-family: 'bw_modelicabold';
  color: #fff;
  font-size: 0.5rem;
  height: 1.1rem;
  width: max-content;
  padding: 0rem 0.3rem;
  line-height: 1.1rem;
  &.STANDARD {
    background: #bfa2ff;
    border: 1px solid #8f4dfa;
  }
  @media (min-width: 1024px) {
    font-size: 0.7rem;
    height: 1.4rem;
    line-height: 1.3rem;
    border-radius: 5px;
    padding: 0rem 0.4rem;
  }
}
