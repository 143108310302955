.billing-summary-page-container {
  display: flex;
  align-items: center;
  .summary-box {
    width: 100%;
    h2 {
      font-family: 'bw_modelicaextrabold';
      font-size: 1.8rem;
      line-height: 2.3rem;
      margin-bottom: 3rem;
      @media (min-width: 1024px) {
        font-size: 2rem;
      }
    }
    h3 {
      font-family: 'bw_modelicabold';
      font-size: 1.1rem;
      margin-bottom: 1.5rem;
    }
    .field-box {
      display: flex;
      flex-direction: column;
      row-gap: 1.4rem;
    }
    .summary-details-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .preference-container {
        width: 40%;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        .preference-box {
          margin-bottom: 2rem;
        }
        .terms-box {
          ul {
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;
            li {
              font-family: 'bw_modelicalight';
              font-size: 0.95rem;
              line-height: 1.2rem;
              letter-spacing: 0.2px;
            }
          }
        }
      }
      .summary-container {
        width: 35%;
        display: flex;
        flex-direction: column;
        .data-box {
          display: flex;
          flex-direction: column;
          margin-bottom: 3rem;
          .item-list {
            display: flex;
            flex-direction: column;
            row-gap: 1.3rem;
            .item-row {
              display: flex;
              align-items: center;
              justify-content: space-between;
              label {
                font-family: 'bw_modelicaregular';
                font-size: 0.95rem;
              }
              p {
                font-family: 'bw_modelicamedium';
                font-size: 1rem;
                letter-spacing: 0.5px;
              }
            }
          }
        }
        .payment-trigger {
          align-self: flex-end;
        }
      }
    }
  }
}
