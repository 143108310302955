.contact-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1.2rem;
  p {
    font-family: 'bw_modelicalight';
    font-size: 1.3rem;
  }
  h3 {
    font-family: 'bw_modelicalight';
    font-size: 4rem;
  }
}
